import React from 'react'
import './DocumentCard.scss';
import ConfirmModal from '../components/modals/ConfirmModal'
import { withRouter } from "react-router";
import { documentAPI } from '../logic/api';
import { htmlDecode } from '../logic/util'
import { addInfoToast, addErrorToast } from '../components/ToastManager'

class DocumentCard extends React.Component {
    constructor(props){
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.deleteClick = this.deleteClick.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.state = {
            showConfirm :false,
        }
    }

    handleClick() {
        this.props.history.push("documents/"+ this.props.document.id);
    }

    deleteClick(e){
        e.stopPropagation()
        this.setState({showConfirm:true})
    }

    modalClose(){
        this.setState({showConfirm:false})
    }

    onConfirm() {
        documentAPI.deleteDocument(this.props.document.workspace_id, this.props.document.id).then(() => {
            this.props.removeDocumentCard(this.props.document.id)
            addInfoToast("Document removed")
        }).catch(() => {
            addErrorToast("Unable to remove document")
        })
    }

    render() {
      return (
        <>
            <div className="document-card" onClick={this.handleClick}>
                <div className="title">{this.props.document.name ? this.props.document.name : "Untitled"}</div>
                <div className="multiline-ellipsis">{this.props.document.master_snippet ? htmlDecode(this.props.document.master_snippet) : ""}</div>
                <div className="details">
                    <div className="languages">{this.props.document.document_languages.length > 1 ? this.props.document.document_languages.length + " Languages" : "1 Language" }</div>
                    {["OWNER", "EDIT"].includes(this.props.workspaceRole) &&
                    <div className="ml-auto" onClick={this.deleteClick}>
                        <i className="far fa-trash"></i>
                    </div>} 
                </div>
            </div>
            <ConfirmModal show={this.state.showConfirm} onClose={this.modalClose} onConfirm={this.onConfirm} data={{
                        title:"Delete document",
                        body:"Are you sure you want to delete the document ?",
                    }}/> 
        </>);
    }
}

export default withRouter(DocumentCard);
