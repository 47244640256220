import AlignerText from './text-element'

export default class AlignerElement extends HTMLElement {
    connectedCallback() {
    }

    attributeChangedCallback(attrName, oldVal, newVal) {

    }

    positionIndex() {
        return Array.prototype.indexOf.call(this.parentElement.children, this)
    }

    getElementType() {
        return this.getAttribute("ali-type")
    }

    isListFragment() {
        let type = this.getElementType()
        return (type && (type.endsWith("-ol") || type.endsWith("-ul")))
    }

    getNewElementType() {
        return this.isListFragment() ? this.getElementType() : "p";
    }

    setElementType(newType, omitEvent) {
        this.setAttribute("ali-type", newType)

        let opElem = this.getOppositeElement();
        if (opElem) opElem.setAttribute("ali-type", newType)

        if (!omitEvent) this.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }));
    }

    getEditor() {
        //sreturn eventManager.getEditor(this.closest(".pane"))
    }

    getOppositeElement() {
        return document.querySelector(`:not([ali-id="${this.getAttribute("ali-id")}"])[ali-rid="${this.getAttribute("ali-rid")}"]`)
    }

    toObject(rowOnly, state) {
        if (rowOnly) return { row_id: this.getAttribute("ali-rid") }

        if (this.getElementType() === "img") {
            return {
                state: state,
                id: this.getAttribute("ali-id"),
                row_id: this.getAttribute("ali-rid"),
                type: this.getAttribute("ali-type"),
                url: this.getAttribute("ali-url"),
                width: parseInt(this.imgContainer.style.width, 10)
            }
        }

        return {
            state: state,
            id: this.getAttribute("ali-id"),
            row_id: this.getAttribute("ali-rid"),
            type: this.getAttribute("ali-type"),
            text_fragment: {
                text_content: this.querySelector("content").innerHTML
            }
        }
    }

    elementViewportInfo(el) {
        var rect = this.getBoundingClientRect();
        return {
            isInView: (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)),
            rect: rect,
        }
    }

    _triggerEvent(eventName, details) {
        this.dispatchEvent(new CustomEvent(eventName, {
            bubbles: true,
            detail: details
        }));
    }
}


AlignerElement.textElements = ["h1", "h2", "h3", "p", "quote", "ul-1", "ul-2", "ul-3", "ol-1", "ol-2", "ol-3"]

AlignerElement.asNode = (elementString) => {
    return document.createRange().createContextualFragment(elementString).children[0];
}

AlignerElement.dummyElement = (type, row_id) => {
    //if (type == "img") return AlignerImage.template(null, null, {row_id})
    return AlignerText.template(null, null, { row_id, type })
}

AlignerElement.template = (elementData, readonly) => {
    //if (elementData.matrixRow.type == "img") return AlignerImage.template(elementData.id, elementData.fragment, elementData.matrixRow)

    return AlignerText.template(elementData.id, elementData.fragment, elementData.matrixRow, readonly)
}