export const genUUID = (prefix) => {
    return  (prefix ? prefix : "") + ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) // eslint-disable-line
    );
}

export const extractInitials = function(str) {
    if (!str) return ""
    
    var initialsArr = str.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g) || [];
    var initials = ((initialsArr.shift() || '') + (initialsArr.pop() || '')).toUpperCase();

    return initials ? initials: " ";
};


export const toLowerAndCapitalize = function(str) {
  if (!str) return ""
  str = str.toLowerCase()
  return str.charAt(0).toUpperCase() + str.slice(1)
};


export const isEmail = function(str) {
  var re = /\S+@\S+\.\S+/;
  return re.test(str);
}

export const checkPassword = function(str) {
  // at least one number, one lowercase and one uppercase letter at least six characters
  var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*?.-_+]{6,}$/;
  return re.test(str);
}

export const b64DecodeUnicode = (str) => {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}

// Get first 4 words, if last word makes it longer then limit we cut the word (Also remove double spaces)
export const getBeginning = function(str) {
    var temp = str.trim().replace(/^((\s?\w+){4})(.*)$/g,'$1')
    return temp.length < 125 ? temp : temp.substring(0,125)  
};


export const removeAllSelections = () => {
  if (window.getSelection) {
      if (window.getSelection().empty) {  // Chrome
          window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {  // Firefox
          window.getSelection().removeAllRanges();
      }
  } else if (document.selection) {  // IE?
      document.selection.empty();
  }

  document.activeElement.blur();
}

export const htmlDecode = (input) => {
  var e = document.createElement('textarea');
  e.innerHTML = input;
  // handle case of empty input
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

export function executePromiseMinTime(promise, time) { 
  return Promise.all([ promise, new Promise(resolve => setTimeout(resolve, time)) ]).then((([r]) => r));
}

export function timeDifference(elapsed) {
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    if (elapsed < msPerMinute) {
         return 'seconds ago';   
    } else if (elapsed < msPerHour) {
        let minutes = Math.round(elapsed/msPerMinute)
        return  minutes === 1 ? '1 minute ago' : minutes + ' minutes ago';   
    } else if (elapsed < msPerDay ) {
        let hours = Math.round(elapsed/msPerHour)
        return  hours === 1 ? '1 hour ago' : hours + ' hours ago';   
    } else if (elapsed < msPerMonth) {
        let days = Math.round(elapsed/msPerDay)
        return  days === 1 ? 'approximately 1 day ago' : 'approximately ' + days + ' days ago';    
    } else if (elapsed < msPerYear) {
        let months = Math.round(elapsed/msPerMonth)
        return  months === 1 ? 'approximately 1 month ago' : 'approximately ' + months + ' months ago';
    } else {
        let years = Math.round(elapsed/msPerYear)
        return  years === 1 ? 'approximately 1 year ago' : 'approximately ' + years + ' years ago';
    }
}

export function toFormatedDateString(dateString, excludeTime) {
  let date = dateString ? new Date(dateString): new Date();

  const weekdayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  var result =   monthNames[date.getMonth()] + " " + date.getDate() + " " + + date.getFullYear() +
      (excludeTime ? "" : (" | " + date.getHours() + ":" + ("00" + date.getMinutes()).slice(-2) + " ")) ;

  return result;
}

export const catchHandler = error => ({ error });
