import AlignerText from './elements/text-element'
import DOMPurify from 'dompurify';

const searchElements = ["BLOCKQUOTE", "H1", "H2", "H3", "H4", "H5", "H6", "LI", "P", "TD"];
const nodeTagToAliType = { "H1": "h1", "H2": "h2", "H3": "h3", "H4": "h3", "H5": "h3", "P": "p", "LI": "1-ul" }
const acceptedTextElement = ["A", "B", "U", "I", "STRONG", "BOLD", "EM"]

export default class PasteParser {
    constructor(pasteEvent) {
        this.clipboardData = (pasteEvent.originalEvent || pasteEvent).clipboardData;

        this.foundFragments = [];
        this.foundText = '';
        this._parse();
    }

    isMultiElementEvent() { return this.foundFragments.length !== 0 }

    insertFoundTextToCursor() {
        let fragment = document.createRange().createContextualFragment(this.foundText)

        window.getSelection().getRangeAt(0).deleteContents();
        window.getSelection().getRangeAt(0).insertNode(fragment);
        window.getSelection().getRangeAt(0).collapse(false);
    }

    _parse() {
        console.log({
            "amld": this.clipboardData.getData("text/amld"),
            "html": this.clipboardData.getData("text/html"),
            "plain": this.clipboardData.getData("text/plain")
        })

        if (this.clipboardData.types.indexOf("text/amld") !== -1) {
            this.alignerContent = this.clipboardData.getData("text/amld");
            let success = this._parseAMLD();

            // If content was retrivied we stop
            if (success) return
        }

        if (this.clipboardData.types.indexOf("text/html") !== -1) {
            this.htmlContent = this.clipboardData.getData("text/html");

            let success = this._parseHTML();

            // If content was retrivied we stop
            if (success) return
        }

        this.plainContent = this.clipboardData.getData("text/plain");
        this._parsePlainText()
    }

    _parsePlainText() {
        var paragraphs = splitToParagraphs(this.plainContent);
        if (paragraphs.length === 1) {
            this.foundText = paragraphs[0].removeUnknownCharacters()
            return
        }

        let fragments = [];
        paragraphs.forEach(paragraph => fragments.push(AlignerText.newNode("p", paragraph.removeUnknownCharacters())));
        this.foundFragments = fragments;
    }

    _parseHTML() {
        let currentElem = { textFragment: null, type: "p" }
        let elems = []
        let pasteElement = document.createElement("div");
        // Clean Newline between tags
        pasteElement.innerHTML = DOMPurify.sanitize(this.htmlContent);
        if (pasteElement.querySelectorAll("*").length === 0) return false;

        pasteElement.style.display = "none";
        document.body.append(pasteElement)

        try {
            let addToCurrentFragment = (newTextFragment) => {
                if (currentElem.textFragment) {
                    currentElem.textFragment.append(newTextFragment)
                } else {
                    currentElem.textFragment = newTextFragment
                }
            }

            let createNewElem = () => {
                if (currentElem.textFragment) {
                    elems.push(currentElem);
                    currentElem = { textFragment: null, type: "p" };
                }
            }

            let setCurrentElemType = type => currentElem.type = type

            parse(pasteElement, addToCurrentFragment, setCurrentElemType, createNewElem,)

            if (currentElem.textFragment) {
                elems.push(currentElem);
            }

        } catch (error) {
            console.log(error)
        }

        pasteElement.remove();

        let fragments = [];
        elems.forEach(elem => { fragments.push(AlignerText.newNode(elem.type, elem.textFragment)) });

        if (fragments.length === 1) {
            this.foundText = this._cleanElementOfUnsupportedElems(pasteElement).replace(/&nbsp;/g, ' ');
            return true
        } else {
            this.foundFragments = fragments;
            return fragments.length !== 0;
        }
    }

    _parseAMLD() {
        try {
            let amldContent = JSON.parse(this.alignerContent)
            if (Array.isArray(amldContent) && amldContent.length > 0) {
                let fragments = [];
                amldContent.forEach((elemData, i) => {
                    if (elemData.type === "img") {
                        //fragments.push(AlignerImage.newNode(elemData.url))
                    } else {
                        let text = document.createRange().createContextualFragment(elemData.text_fragment.text_content)
                        fragments.push(AlignerText.newNode(elemData.type, text))
                    }
                })

                this.foundFragments = fragments;
                return true;
            }
        } catch { }

        return false
    }

    _cleanElementOfUnsupportedElems(elem) {
        var self = this;
        elem.normalize();

        [].slice.call(elem.querySelectorAll("*"), 0).reverse().forEach(el => {
            if (["A", "B", "U", "I", "STRONG", "BOLD", "EM"].includes(el.nodeName)) {
                var href = el.href;
                while (el.attributes.length > 0)  // Remove all attributes on supported elements
                    el.removeAttribute(el.attributes[0].name);

                if (href) el.href = href;

                // If element is unsupported we unwrap it
            } else {
                if (el.tagName === "BR") {
                    el.outerHTML = " "
                } else {
                    self._wrapElemInTagsByStyles(el)
                    if (searchElements.includes(el.nodeName.toUpperCase()))
                        el.innerHTML += " "

                    el.outerHTML = el.innerHTML;
                }
            }
        });

        return elem.innerHTML.removeUnknownCharacters({ replaceChar: ' ' });
    }

    _getListType(elem) {
        let level = 0;
        let listType = "ul";

        while (elem) {
            elem = elem.parentNode;
            if (elem && (elem.nodeName === "UL" || elem.nodeName === "OL")) {
                level++;
                listType = elem.nodeName.toLowerCase();
            }
        }

        level = level > 3 ? 3 : (level < 1 ? 1 : level)
        return level.toString() + "-" + listType;
    }

    _wrapElemInTagsByStyles(elem) {
        if (elem.style.fontWeight > 400) elem.innerHTML = "<b>" + elem.innerHTML + "</b>"
        if (elem.style.textDecoration === 'underline') elem.innerHTML = "<u>" + elem.innerHTML + "</u>"
        if (elem.style['font-style'] === 'italic') elem.innerHTML = "<i>" + elem.innerHTML + "</i>"
    }
}

function splitToParagraphs(str) {
    var paragraphs = str.split("\n");

    paragraphs.forEach(function (paragraph, i, arr) {
        arr[i] = paragraph.removeUnknownCharacters()
    });

    return paragraphs.filter(p => p.length !== 0);
};

// Remove all whitespace characters except space
String.prototype.removeUnknownCharacters = function (options) {
    if (!options) options = {
        replaceChar: "",
    }

    return this.replace(/&nbsp;/g, ' ').replace(/[^\S ]/g, options.replaceChar)
};

function isBold(elem) {
    return elem.parentNode.closest("b, bold, strong, h5") !== null
}

function isItallic(elem) {
    return elem.parentNode.closest("em,i") !== null
}

function isUnderline(elem) {
    return elem.parentNode.closest("u") !== null
}

function isLink(elem) {
    return elem.parentNode.closest("a") !== null
}

function getType(elem) {
    let typeNode = elem.closest(searchElements.join(","))
    if (typeNode) {
        if (["H1", "H2", "H3"].includes(typeNode.nodeName)) {
            return "h1"
        }

        if ("LI" === typeNode.nodeName) {
            return _getListType(elem)
        }
    }

    return "p"
}

function _getListType(elem) {
    let level = 0;
    let listType = "ul";

    while (elem) {
        elem = elem.parentNode;
        if (elem && (elem.nodeName === "UL" || elem.nodeName === "OL")) {
            level++;
            listType = elem.nodeName.toLowerCase();
        }
    }

    level = level > 3 ? 3 : (level < 1 ? 1 : level)
    return level.toString() + "-" + listType;
}

function WrapElemInStyles(elem) {
    elem.textContent = elem.textContent.removeUnknownCharacters({ replaceChar: ' ' })
    let currentElem = elem.cloneNode()

    if (isBold(elem)) {
        let b = document.createElement("b")
        b.innerHTML = currentElem.textContent;
        currentElem = b;
    }

    if (isItallic(elem)) {
        let i = document.createElement("i")
        i.innerHTML = currentElem.innerHTML ? currentElem.innerHTML : currentElem.textContent;
        currentElem = i;
    }

    if (isUnderline(elem)) {
        let u = document.createElement("u")
        u.innerHTML = currentElem.innerHTML ? currentElem.innerHTML : currentElem.textContent;
        currentElem = u;
    }

    if (isLink(elem)) {
        const r = new RegExp('^(?:[a-z]+:)?//', 'i');
        let href = elem.parentNode.closest("a").href;
        if (r.test(href)) {
            let a = document.createElement("a")
            a.href = href;
            a.innerHTML = currentElem.innerHTML ? currentElem.innerHTML : currentElem.textContent;
            currentElem = a
        }
    }

    let f = document.createDocumentFragment();
    f.append(currentElem);
    return f;
}

function parse(element, appendToCurrent, setParaType, createNewPara) {
    if (element.nodeName === "IMG" || element.nodeName === "SVG") return;
    
    if (element.nodeType === 3) {
        //This does not use Javascript's "\s" because that includes non-breaking spaces (and also some other characters).
        if((/[^\t\n\r ]/.test(element.textContent))) 
            appendToCurrent(WrapElemInStyles(element))
    } else {
        for (var i = 0; i < element.childNodes.length; i++) {
            parse(element.childNodes[i], appendToCurrent, setParaType, createNewPara);
        }
        
        var d = getComputedStyle(element).getPropertyValue('display');
        if (d.match(/^block/) || d.match(/list/) || element.tagName === 'BR') {
            setParaType(getType(element))
            createNewPara()
        }
    }
}

function getNodeStyle(n, p) {
    return n.currentStyle ?
        n.currentStyle[p] :
        document.defaultView.getComputedStyle(n, null).getPropertyValue(p);
}
 
function isNodeBlock(node) {
    if (node.nodeType === document.TEXT_NODE) { return false; }
    var d = getNodeStyle(node, 'display');//this is irrelevant if the node isn't currently in the current DOM.
    if (d.match(/^block/) || d.match(/list/) || d.match(/row/) ||
        node.tagName === 'BR' || node.tagName === 'HR' ||
        node.tagName === 'DIV' // div,p,... add as needed to support non-DOM nodes
    ) {
        return true;
    }
    return false;
}
 
function htmlToText(htmlOrNode, isNode) {
    var node = htmlOrNode;
    if (!isNode) {
        node = document.createElement("span")
        node.innerHTML = htmlOrNode
        //TODO: inject "unsafe" HTML into current DOM while guaranteeing that it won't
        //      change the visible DOM so that `isNodeBlock` will work reliably
        var result = '';
        if (node.nodeType === document.TEXT_NODE) {
            // Replace repeated spaces, newlines, and tabs with a single space.
            result = node.nodeValue.replace(/\s+/g, ' ');
        } else {
            for (var i = 0, j = node.childNodes.length; i < j; i++) {
                result += htmlToText(node.childNodes[i], true);
                if (i < j - 1) {
                    if (isNodeBlock(node.childNodes[i])) {
                        result += '\n';
                    } else if (isNodeBlock(node.childNodes[i + 1]) &&
                        node.childNodes[i + 1].tagName !== 'BR' &&
                        node.childNodes[i + 1].tagName !== 'HR') {
                        result += '\n';
                    }
                }
            }
        }
        return result;
    }
}