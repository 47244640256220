import { removeAllSelections } from '../util'
import { removeMultiselection } from '../selection'

class ToolBar extends HTMLElement {
	constructor() {
        super();

        // TODO Remove on reload
        this.isMultiselect = false;
        this.selectionToolbarHandler = this.selectionToolbarHandler.bind(this)
        this.addEventListener("paste", (e) =>{e.stopImmediatePropagation()})
        document.addEventListener('selectionchange', this.selectionToolbarHandler);
    }

    connectedCallback() {
        this.innerHTML = toolBarInner;
        this.scrollParent = this.closest(".allowed-selection-area");
        this.toolbarRow = this.querySelector(".toolbar-row");
        this.toolbarLeft = this.querySelector(".toolbar-left");
        this.linkInput = this.querySelector("input");

        this.querySelector("[data-translate]").addEventListener("click", this.multiTranslate.bind(this));
        [].forEach.call((this.querySelectorAll("[data-type]")), el => el.addEventListener("click", this.typeChange.bind(this)));
        [].forEach.call((this.querySelectorAll("[data-style]")), el => el.addEventListener("click", this.styleChange.bind(this)));
        this.querySelector("#link-dropdown-toggle").addEventListener("click", this.linkDropdownClick.bind(this))
        this.querySelector(".link").addEventListener("click", this.linkClick.bind(this))
        this.querySelector(".unlink").addEventListener("click", this.unlinkClick.bind(this))
    }

    disconnectedCallback() {
        document.removeEventListener('selectionchange', this.selectionToolbarHandler);
    }

    multiTranslate(e) {
        [].forEach.call((document.querySelectorAll("[ali-selected=true]")), el => {
            el.translateFromOpposite();
        });

        removeMultiselection()
    }

    typeChange(e) {
        var clickedType = e.target.closest("[data-type]").getAttribute("data-type")

        if (!this.isMultiselect) {
            var target = document.activeElement.closest(".aligner-fragment")
            var currentType =  target ? target.getAttribute("ali-type") : "p";
            var desiredState = (currentType === clickedType) ? "p" : clickedType;
    
            if (target) {
                target.setElementType(desiredState)
                this.setActiveElems();
            }
        } else {
            let alreadySameType = true;
            [].forEach.call((document.querySelectorAll("[ali-selected=true]")), el => {
                if (el.nodeName.toUpperCase() !== "ALI-TITLE")
                    alreadySameType = alreadySameType && (el.getAttribute("ali-type") === clickedType)
            });

            // Toggle logic
            if (alreadySameType) clickedType = "p";

            [].forEach.call((document.querySelectorAll("[ali-selected=true]")), el => {
                if (el.nodeName.toUpperCase() !== "ALI-TITLE")
                    el.setElementType(clickedType)
            });
        }

    }

    selectionToolbarHandler (e) {
        if( /* selection.active || */ (window.getSelection().rangeCount === 0 || window.getSelection().getRangeAt(0).toString().length === 0)) {
            if (!document.activeElement || !document.activeElement.closest("#link-edit"))
                this.toolbarRow.style.display = "none"
        } else if ((this.parentElement.compareDocumentPosition(document.activeElement) & Node.DOCUMENT_POSITION_CONTAINED_BY) && window.getSelection().getRangeAt(0).toString().length !== 0 
                && document.activeElement.closest(".aligner-fragment")) {

            this.setActiveElems();
            let parentRect = this.parentElement.getBoundingClientRect()
            let rect = window.getSelection().getRangeAt(0).getBoundingClientRect()
            
            this.toolbarRow.style.top = (rect.y + document.defaultView.pageYOffset + this.scrollParent.scrollTop - 105) + "px";
            this.toolbarLeft.style.width = (rect.x - parentRect.x - 25) + "px";   
            this.toolbarRow.style.display = "flex" 
            this.toolbarRow.classList.remove("multiselect") 
            this.isMultiselect = false;
        } 
    }

    showToolbarAt(x, y) {
        let parentRect = this.parentElement.getBoundingClientRect()
        
        this.toolbarRow.style.top = (y + document.defaultView.pageYOffset + this.scrollParent.scrollTop - 105) + "px";
        this.toolbarLeft.style.width = (x - parentRect.x - 25) + "px";   
        this.toolbarRow.style.display = "flex"
        this.toolbarRow.classList.add("multiselect") 
        this.isMultiselect = true;
    }

    styleChange(e) {
        var clickedStyle = e.target.closest("[data-style]").getAttribute("data-style");
        // TODO ? Maybe allow only if not selectionmode ?
        if (!this.isMultiselect) {
            document.execCommand(clickedStyle, false, false)
            // getCurrentContentElem().normalize(); // TOD
        } else {
            var windwSelection = window.getSelection();

            [].forEach.call((document.querySelectorAll("[ali-selected=true]")), el => {
                if (el.nodeName.toUpperCase() !== "ALI-TITLE") {
                    var range = document.createRange();
                    windwSelection.removeAllRanges();
                    windwSelection.addRange(range);
                    range.selectNodeContents(el.querySelector("content"))
                    document.execCommand(clickedStyle, false, false)
                    el.normalize();
                }
            });

            removeAllSelections()
        }
    }

    linkDropdownClick(e) {
        if(document.activeElement.closest(".aligner-fragment")) {
            var a = window.getSelection().anchorNode.parentElement.closest("a")
            if (a) {
                this.linkInput.value = a.href
            }  else {
                this.linkInput.value = ""
            }

            this.lastRange = window.getSelection().getRangeAt(0).cloneRange()
        }

        this.linkInput.focus()
    }

    linkClick(e) {
        if (!this.lastRange) return

        window.getSelection().removeAllRanges()
        window.getSelection().addRange(this.lastRange)
        document.execCommand('CreateLink', false, this.linkInput.value);
        this.linkInput.value = "";
    }
    
    unlinkClick(e) {
        if (!this.lastRange) return

        window.getSelection().removeAllRanges()
        window.getSelection().addRange(this.lastRange)
        document.execCommand('unlink', false, false);
        this.linkInput.value = "";
    }

    setActiveElems() {
        var currentElem = window.getSelection().getRangeAt(0).commonAncestorContainer;
        var activeElems = []

        outerLoop:
        while (currentElem.parentElement) {
            switch (currentElem.nodeName) {
                case "STRONG":
                case "BOLD":
                case "EM":
                case "B":
                    activeElems.push("ITEM-B") 
                    break;
                case "A":
                    activeElems.push("ITEM-A") 
                    break;
                case "I":
                    activeElems.push("ITEM-I") 
                    break;
                case "U":
                    activeElems.push("ITEM-U") 
                    break;
                case "ALI-TXT":
                    var type = currentElem.getAttribute("ali-type")
                    if (type === "h1"){ 
                        activeElems.push("TYPE-H1") 
                    }else if (type.includes("ol")) {
                        activeElems.push("TYPE-OL") 
                    } else if (type.includes("ul")) {
                        activeElems.push("TYPE-UL") 
                    }

                    break outerLoop;
                // no default
            }

            currentElem = currentElem.parentElement;
        }

        this.setAttribute("active-elems", activeElems)
    }
    
}
    
if (!window.customElements.get("editor-toolbar")) window.customElements.define('editor-toolbar', ToolBar);

const toolBarInner = `<div class="toolbar-row disable-select-box" style="display:none">
    <div class="toolbar-left" style="flex-shrink: 1; min-width: 12px;"></div>
    <div class="toolbar list-group list-group-horizontal-sm">
        <a class="py-0 px-2" data-translate>Translate</a>
        <a class="py-0 px-2" data-style="bold"><i class="far fa-bold"></i></a>
        <a class="py-0 px-2" data-style="italic"><i class="far fa-italic"></i></a>
        <a class="py-0 px-2 " data-style="underline"><i class="far fa-underline"></i></a>
        <div id="link-dropdown-toggle" class="py-0 px-2">
            <a role="button" id="dropdownTextLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> 
            <i class="far fa-link"></i>
            </a>
            <div id="link-edit" class="dropdown-menu" aria-labelledby="dropdownTextLink">
                <input placeholder="https://example.com">
                <button class="btn btn-reverse link">Link</button>
                <button class="btn btn-reverse unlink">Unlink</button>
            </div>
        </div>
        <a class="py-0 px-2 group-start" data-type="h1"><i class="far fa-text-size"></i></a>
        <a class="py-0 px-2" data-type="1-ol"><i class="far fa-list-ol"></i></a>
        <a class="py-0 px-2" data-type="1-ul"><i class="far fa-list-ul"></i></a>
        </div>
    <div style="flex-grow: 1; flex-shrink: 1; min-width: 12px;"></div>
</div>`

/*
 selectionToolbar.find("[data-type]").click(function (e) {
        var target = document.activeElement.closest(".aligner-fragment")
        var currentType =  target ? target.getAttribute("ali-type") : "p";
        var clickedType = this.getAttribute("data-type")
        var desiredState = currentType == clickedType ? "p" : clickedType;

        self.turnFragmentTypeInto(desiredState, target);
        if (target) selectionToolbar.setActiveElems();
    });

    selectionToolbar.find("[data-style]").click(function (e) {
        self._turnSelectionInto(this.getAttribute("data-style"))
    });

    selectionToolbar.find("[data-action]").click(function (e) {
        self._turnFragmentToList(false, this.getAttribute("data-action") == "indent", true)
    })
    
    selectionToolbar.find("[data-align]").click(function (e) {
        var target = document.activeElement.closest(".aligner-fragment")
        self.turnFragmentAlignemntInto(this.getAttribute("data-align"), target)
    })

    selectionToolbar.find("#link-dropdown-toggle").mousedown(function (e) {
        e.stopPropagation()
        if(document.activeElement.closest(".aligner-fragment")) {
            var a = window.getSelection().anchorNode.parentElement.closest("a")
            if (a) {
                selectionToolbar.find("input").val(a.href)
            }  else {
                selectionToolbar.find("input").val("")
            }

            selectionToolbar.lastRange = window.getSelection().getRangeAt(0).cloneRange()
        }

        selectionToolbar.find("input").focus()
    })

    selectionToolbar.find(".link").click(function (e) {
        if (!selectionToolbar.lastRange) return

        window.getSelection().removeAllRanges()
        window.getSelection().addRange(selectionToolbar.lastRange)
        document.execCommand('CreateLink', false, selectionToolbar.find("input").val());
        selectionToolbar.find("input").val("")
    })

    selectionToolbar.find(".unlink").click(function (e) {
        if (!selectionToolbar.lastRange) return
        window.getSelection().removeAllRanges()
        window.getSelection().addRange(selectionToolbar.lastRange)
        document.execCommand('unlink', false, false);
    })

    selectionToolbar.on("paste", (e) =>{e.stopImmediatePropagation()})

    selectionToolbar.on('hide.bs.dropdown', function () {
        if (!selectionToolbar.lastRange) return
        window.getSelection().removeAllRanges()
        window.getSelection().addRange(selectionToolbar.lastRange)
        selectionToolbar.lastRange = null;
    })
*/