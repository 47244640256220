import React, { useState } from "react";
import { documentAPI } from '../logic/api'
import { addInfoToast, addErrorToast } from '../components/ToastManager'
import { useHistory } from "react-router";

import './NewWorkspace.scss'

export default function NewWorkspace(props) {
    const [workspaceName, setWorkspaceName] = useState("");
    let history = useHistory();

    let createNewWorkspace = () => {
        if (!workspaceName) {
            addErrorToast("Workspace name required")
            return
        }

        documentAPI.newWorkspace(workspaceName).then(newWorkspace => {
            addInfoToast("New workspace created")
            history.push("/workspace/"+ newWorkspace.id + "/");
        }).catch( () => {
            addErrorToast("Something went wrong")
        })
    }

    return (
        <div class="new-workspace" style={{minHeight:"100vh"}}>
            <div class="container py-5">
                <div className="row d-flex flex-column">
                    <h1 class="text-center position-relative"> 
                        <a href="#" onClick={history.goBack} class="link-cancel">Cancel</a>
                        Name your workspace
                    </h1>
                    <p class="sub-heading">Fill in some details and start translating</p>

                    <div class="row">
                        <div class="col-md-10 mx-auto">
                            <div class="frame-box mt-5 mx-auto">
                                <div className="input-container d-flex">
                                    <input id="workspace-name" type="text" placeholder="Workspace name" value={workspaceName} onChange={(e) => setWorkspaceName(e.target.value)}  />
                                    <small className="hint">Workspace name</small>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-12 text-center">
                                    <button id="team-account-details-btn" class="btn btn-primary btn-lg btn-frame" onClick={createNewWorkspace}>Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}