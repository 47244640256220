import React from 'react'
import './TextEditor.scss';
import './TextEditorElements.scss';
import '../logic/editor/editorCore.js'
import {genUUID} from '../logic/util'
import '../logic/editor/toolBar'
import LanguageSelect from './LanguageSelect'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

class TextEditor extends React.Component {
    constructor(props) {
        super(props)
        this.editorRef = React.createRef();
        this.state = {
            documentCreated: false,
        };
    }

    componentDidMount() {
        this.editorRef.current.addEventHandler(this.props.eventManager);
        this.props.eventManager.masterEditor = this.editorRef.current;

        this.editorRef.current.unstartedDocument = this.props.isNewPage;
        if (this.props.isNewPage) {
            this.editorRef.current.initialLanguageDetector();

            this.props.eventManager.on("onCreateNewDocument", ((r) => {
                this.setState({ documentCreated: true });
            }))
        }
    }

    render() {
        let editorElem;
        if (this.props.isNewPage ) { 
            editorElem = ( 
                <block-editor class="master" ref={this.editorRef} style={{width:"100%"}}>
                    <ali-title class="disable-select-box" >
                        <content contentEditable placeholder="Enter title"></content>
                    </ali-title>
                    <ali-txt ali-id={genUUID()} ali-type="p" ali-rid={genUUID()} ali-empty="true" class="new aligner-fragment" >
                        <content contentEditable></content>
                        <div className="extra"><div className="update-translation">Update</div></div>
                        <div className="active-users"></div>
                    </ali-txt>
                </block-editor>)
        } else {
            editorElem = <block-editor class="master" ref={this.editorRef} style={{width:"100%"}} />
        }

        return (
            <div className={(!this.props.isNewPage || this.state.documentCreated) ? "text-editor" : "text-editor doc-not-created"}>
                <MasterHeader eventManager={this.props.eventManager} editor={this.editorRef.current} isNewPage={this.props.isNewPage} documentCreated={this.state.documentCreated} />
                { editorElem }
                <editor-toolbar />
            </div>);
    }
}

export default TextEditor;


class MasterHeader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            viewOnly: false,
        };

        this.setMasterLanguage = this.setMasterLanguage.bind(this);
    }

    componentDidMount() {
        this.props.eventManager.on("onMasterLanguageDetected", ({language}) => {
            this.setState({ language });
        })

        this.props.eventManager.on("onMasterEditorLoaded", ( ({language}, viewOnly) => {
            if (language) {
                this.setState({ language, viewOnly }); 
            } else {
                this.setState({ language: "Select language", viewOnly });
            }
        }))
    }

    setMasterLanguage(languageInfo) {
        this.props.eventManager.setMasterLanguage(languageInfo)
    }

    render() {
        return (
            <div className={this.props.isNewPage && !this.props.documentCreated ? "unstarted" : ""}>
                <header className="d-flex align-items-centers disable-select-box" style={{height:"50px"}}>
                    <span className="item active d-flex align-items-center" data-toggle="collapse"
                    data-target="#master-language-select" aria-expanded="false" aria-controls="master-language-select" >
                        {this.state.language ? this.state.language : "Auto detect language"}</span>
                    {!this.state.viewOnly &&
                    <OverlayTrigger transition={false} placement="bottom" overlay={<Tooltip>Change detected language</Tooltip>}>
                        <div className="set-language item ml-2"  id="master-language-select-toggle" data-toggle="collapse"
                    data-target="#master-language-select" aria-expanded="false" aria-controls="master-language-select" >
                            <i class="fas fa-cog"></i>
                        </div>
                    </OverlayTrigger>
                    }
                </header>
                {!this.state.viewOnly && <LanguageSelect id="master-language-select" languageClick={this.setMasterLanguage}/>}
            </div>
        )
    }
}