import React, { useState } from "react";
import { Link } from "react-router-dom"
import logo from '../resources/main-logo.svg'
import login from '../resources/side-banner.svg'
import {documentAPI} from '../logic/api'
import Form from 'react-bootstrap/Form'

export default function Forgot(props) {
    const [forgotForm, setForgotForm] = useState({ email:"", errors:{}});

    function validateForm() {
        var validated = true;
        let tmpErrors = {}

        if (forgotForm.email.length < 1) {
            tmpErrors = Object.assign(tmpErrors, { email: "Email is required"})
            validated = false;
        } 

        setForgotForm({email:forgotForm.email, errors:tmpErrors})
        return validated
    }

    function handleSubmit(event) {
        event.preventDefault();
        if (validateForm()) {
            documentAPI.forgot(forgotForm.email).then(() => {
                setForgotForm({email:forgotForm.email, errors:{general:"Email sent"}})
            }).catch(err => {
                if (err.status_code && err.status_code === "NO_USER") {
                    setForgotForm({email:forgotForm.email, errors:{email:"No such user"}})
                } else {
                    setForgotForm({email:"", errors:{general:"Something went wrong. Try again later."}})
                }
            })
        }
    }

    return (
        <div className="sign-up-view row" style={{width:"100vw", minHeight:"100vh"}}>
            <div className="col-6" style={{background:"#f5f6f8"}}>
                <div style={{height:"heigth:200px", margin:"32px 48px"}}><img src={logo} /></div>
                <div className="side-logo"><img src={login} /></div>
            </div>
            <div className="col-6">
                <div style={{height:"heigth:200px", margin:"32px 48px", float:"right"}}>
                    <Link to="/sign-in" className="df-btn auth-switch">Sign-in</Link>
                </div>
                <div  className="form-wrap">
                    <div className="auth-form" style={{margin:"auto", paddingBottom:"65px"}}>
                        <div style={{marginTop:"-50px", maxWidth: "510px"}}>
                            <form onSubmit={handleSubmit}>
                                <h1 className="login-title">Reset your password</h1>
                                <p className="login-details mb-0">Enter your email and we’ll send a link to reset your password.</p>
                                <p className="general-error mb-5">{forgotForm.errors.general}</p>
                                <div className="mx-auto text-left" style={{width:"75%"}}>
                                    <Form.Group>
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control type="email" placeholder="Enter email" onChange={e => setForgotForm({email:e.target.value, errors:{}})} />
                                        <Form.Text className="error-text-sm">{forgotForm.errors.email}</Form.Text>
                                    </Form.Group>
                                </div>
                                <button className="df-btn" style={{marginTop:"32px"}}>Send email</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}