import React, { useState } from 'react'
import '../logic/editor/editorCore.js'
import natureMan from '../resources/nature-man.svg'
import LanguageSelect from './LanguageSelect'
import ConfirmModal from './modals/ConfirmModal'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

class EditorRelated extends React.Component {
    constructor(props) {
        super(props)
        this.editorRef = React.createRef();
        this.state = {
            documentID : "",
            documentLoaded: false,
        };

        this.languageClick = this.languageClick.bind(this);
        this.changeLanguageClick = this.changeLanguageClick.bind(this);
        this.deleteLanguage = this.deleteLanguage.bind(this);
    }

    componentDidMount() {
        this.editorRef.current.addEventHandler(this.props.eventManager);
        this.props.eventManager.relatedEditor = this.editorRef.current;

        // If new document is created we can change related editor image
        this.props.eventManager.on("onCreateNewDocument", ((r) => {
            this.setState({ documentLoaded: true });
        }))

        this.props.eventManager.on("onLanguagesChange", ((r) => {
            this.setState({ languages: r });
        }))

        this.props.eventManager.on("onRelatedEditorLoaded", ((relatedMeta, viewOnly) => {
            this.setState({ documentID: relatedMeta ? relatedMeta.key : "", documentLoaded: true, viewOnly });
        }))
    }

    deleteLanguage () {
        this.props.eventManager.deleteLanguage({languageKey: this.state.documentID})
    }

    languageClick (languageInfo) {
        this.props.eventManager.addLanguage(languageInfo)
    }

    changeLanguageClick (e) {
        this.props.eventManager.changeLanguage(e.target.getAttribute("data-lang-id"))
    }

    render() {
        return (
            <div className={(!this.props.isNewPage || this.state.documentLoaded) ? "text-editor" : "text-editor doc-not-created"}>
                <RelatedHeader 
                    selectedLanguage={this.state.documentID} 
                    languages={this.state.languages} 
                    documentLoaded={(this.state.documentLoaded)} 
                    viewOnly={this.state.viewOnly}
                    languageClick={this.languageClick} 
                    deleteLanguage={this.deleteLanguage}
                    changeLanguageClick={this.changeLanguageClick} />
                <block-editor class="related" ref={this.editorRef} style={{width:"100%"}} />
                <editor-toolbar />
                { (this.props.isNewPage && !this.state.documentLoaded) && !this.state.documentID && <NothingToTranslate />}
                { this.state.documentLoaded && !this.state.documentID && !this.state.viewOnly && <AddNewTranslation languageClick={this.languageClick} />}
                { this.state.documentLoaded && !this.state.documentID && this.state.viewOnly && <NoTranslations />}
            </div>);
    }
}

export default EditorRelated;


function RelatedHeader(props) {
    const [showDeleteModal, setDeleteModal] = useState(false);

    return (
        <div>
            <header className="related-header d-flex align-items-centers disable-select-box" style={{height:"50px"}}>
                <div style={{display: "flex", height: "50px", flexWrap: "wrap"}}>
                    {props.languages && props.languages.map((value, index) => { 
                        return (
                        <span key={value.key} 
                            className={(props.selectedLanguage === value.key) ? "item d-flex align-items-center mr-2 active" : "item d-flex align-items-center mr-2"} 
                            data-lang-id={value.key}
                            onClick={props.changeLanguageClick}>
                            {value.language ? value.language : "Unknown Language"}
                        </span>)
                    })}
                </div>
                
                {!props.viewOnly && 
                        <OverlayTrigger transition={false} placement="bottom" overlay={<Tooltip>Translate this content into more languages</Tooltip>}>
                        <div className="add-language ml-auto mr-2" data-toggle="collapse"
                            data-target="#languageList" aria-expanded="false" aria-controls="languageList" >
                            More languages
                        </div>
                        </OverlayTrigger>}
                { !props.viewOnly && props.selectedLanguage && (
                    <OverlayTrigger transition={false} placement="bottom" overlay={<Tooltip>Remove translation</Tooltip>}>
                    <div className="delete-language item mr-2" onClick={() => {setDeleteModal(true)}}>
                        <i className="fas fa-trash-alt"></i>
                    </div>
                    </OverlayTrigger>)}
            </header>
            <ConfirmModal show={showDeleteModal}  onClose={() => {setDeleteModal(false)}}  onConfirm={() => {setDeleteModal(false); props.deleteLanguage()}} 
                    data={{
                        title:"Delete language",
                        body:"Are you sure you want to delete this language ?",
                        confirmText: "Delete",
                        modalIcon : <i class="fad fa-trash-alt"></i>,
                    }}/> 
            <LanguageSelect languageClick={props.languageClick} id="languageList" />
        </div>
    )
}

function NothingToTranslate(params) {
    return (
        <figure className="mx-auto">
            <img className="default" src={natureMan} alt="No translations" />
            <figcaption className="caption">
                <span className="small-title">Nothing translated yet</span>
                <p>When ready with your text, choose languages and apply machine translation</p>
            </figcaption>
        </figure>)
}


function AddNewTranslation(props) {
    let languageSelected = (e) => {
        props.languageClick({
            language : e.target.getAttribute("data-lang-name"),
            languageCode : e.target.getAttribute("data-lang"),
        })
    }

    return (
        <div className="language-suggestion mx-auto text-center">
            <div className="add-translation-title">Add translation</div>
            <div className="suggested-lang-list">
                    <span className="suggested-lang" data-lang="en" data-lang-name="English" onClick={languageSelected}>English</span>
                    <span className="suggested-lang" data-lang="et" data-lang-name="Estonian" onClick={languageSelected}>Estonian</span>
                    <span className="suggested-lang" data-lang="es" data-lang-name="Spanish" onClick={languageSelected}>Spanish</span>
                    <span className="suggested-lang" data-lang="fr" data-lang-name="French" onClick={languageSelected}>French</span>
                    <span className="suggested-lang" data-lang="ru" data-lang-name="Russian" onClick={languageSelected}>Russian</span>
                    <span className="suggested-lang" data-lang="de" data-lang-name="German" onClick={languageSelected}>German</span>
                    <span className="suggested-lang" data-lang="fi" data-lang-name="Finnish" onClick={languageSelected}>Finnish</span>
            </div>
            <p className="color-gray mt-5">+132 languages <br></br> Click "More languages"</p>
        </div>)
}

function NoTranslations(params) {
    return (
        <figure className="mx-auto">
            <img className="default" src={natureMan} alt="No translations" />
            <figcaption className="caption">
                <span className="small-title">No translations created yet</span>
                <p>You do not have the rights to add more languages</p>
            </figcaption>

        </figure>)
}
