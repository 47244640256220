import React, { useRef, useState } from 'react'
import languages from '../resources/languages.json'
import useOutsideClick from "./useOutsideClick";

export default function LanguageSelect(props) {
    const ref = useRef();
    const [langSearch, setLangSearch] = useState("");

    useOutsideClick(ref, () => {
        ref.current.classList.remove("show");
    });

    let languageSelected = (e) => {
        ref.current.classList.remove("show");
        setLangSearch("")
        props.languageClick({
            language : e.target.getAttribute("data-lang-name"),
            languageCode : e.target.getAttribute("data-lang"),
        })
    }

    //TODO
    let selectLangEnter = (e) => {
        e.preventDefault();
        let lang = languages.find(e => e.eng.toLowerCase().includes(langSearch.toLowerCase()));
        setLangSearch("")
        props.languageClick({
            language : lang.eng,
            languageCode : lang['iso-639-1'],
        })
    }
    
    const langs = languages.filter(e => e.eng.toLowerCase().includes(langSearch.toLowerCase())).map(lang => (
        <div key={lang['iso-639-1']} className="dropdown-item-language item d-flex align-items-center" data-lang={lang['iso-639-1']} data-lang-name={lang.eng} onClick={languageSelected} >{lang.eng}</div>
    ))

    return (
        <div ref={ref} className="collapse languageListContainer disable-select-box" aria-labelledby="dropdownMenuLink" id={props.id}>
            <form onSubmit={selectLangEnter}>
                <input type="search" placeholder="Type language" value={langSearch} onChange={e => setLangSearch(e.target.value)} />
            </form>
            {langs}
        </div>)
}