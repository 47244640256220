import Selection from '@simonwep/selection-js'
import { removeAllSelections } from '../logic/util'

export const selection = new Selection({
    singleClick: false,
    selectables: [".aligner-fragment, ali-title"],
    boundaries: ['.allowed-selection-area'],
    selectionAreaContainer: '.allowed-selection-area'
});

export const startSelectionListeners = () => {
    document.addEventListener('copy', documentCopy)
    document.addEventListener('keydown', multiselectKeydown)
}

export const stopSelectionListeners = () => {
    if (selection.selectionEditor) selection.selectionEditor.editorInfo.multiselectActive = false;
    selection.selectionEditor = null;
    selection.toolbar = null;
    document.removeEventListener('copy', documentCopy)
    document.removeEventListener('keydown', multiselectKeydown)
}

function documentCopy(e) {
    if(selection.selectionEditor) selection.selectionEditor.copyMultiSelectToClipboard(e)
}

function multiselectKeydown(e) {
    if(selection.selectionEditor && e.keyCode !== 67) {
        e.preventDefault();
        e.stopPropagation();

        //selection.selectionEditor.trigger(e)
        switch (e.keyCode) {
            // Return | Enter
            case 13:
                let firstActive = selection.selectionEditor.querySelector("[ali-selected]");
                if (firstActive) firstActive.addCursor(false)
                removeMultiselection();

                break
            // Backspace
            case 8:
                if (selection.selectionEditor) {
                    let elems = selection.selectionEditor.querySelectorAll("[ali-selected]");
                    [].forEach.call(elems, function(el) {
                        if (el.nodeName.toUpperCase() === "ALI-TITLE") {
                            el.setContent("")
                        } else {
                            if (selection.selectionEditor.triggerHandler("onDeleteFragment", { element: el })) {
                                el.remove();
                            } else {
                                el.setContent("", true)
                                el.setAttribute("ali-dummy", true);
                            }    
                        }
            
                    })
                }
                
                removeMultiselection();
                break

            // no default
        }
    }
}

export function removeMultiselection(params) {
    if (selection.selectionEditor) {
        selection.selectionEditor.editorInfo.multiselectActive = false;
        selection.selectionEditor = null;
    }

    [].forEach.call(document.querySelectorAll("[ali-selected]"), function(el) {
        el.removeAttribute("ali-selected");
    });

    [].forEach.call(document.querySelectorAll(".toolbar-row"), function(el) {
        el.style.display = "none"
    })
}

selection.on('beforestart', evt => {
    if (evt.oe.target.closest(".toolbar-row")) return;
    removeMultiselection()

    // We allow selection if the target isn't under any editable element
    return (evt.oe.target.closest(".aligner-fragment") == null && 
        evt.oe.target.closest(".disable-select-box")  == null && 
        evt.oe.target.closest("input")  == null ) 
        
}).on('move', evt => {
    evt.changed.added.forEach((elem) => {
        if (!selection.selectionEditor) {
            selection.selectionEditor = elem.closest("block-editor");
            selection.toolbar = selection.selectionEditor.parentElement.querySelector("editor-toolbar");
            selection.selectionEditor.editorInfo.multiselectActive = true
        }
        
        // If editor contains fragment we add it to selection
        if(selection.selectionEditor.compareDocumentPosition(elem) & Node.DOCUMENT_POSITION_CONTAINED_BY) {
            elem.setAttribute("ali-selected", true)
        } 
    })

    evt.changed.removed.forEach((elem) => {
        elem.removeAttribute("ali-selected")
    })

    if (evt.selected.length === 0 && selection.selectionEditor) {
        selection.selectionEditor.editorInfo.multiselectActive = false;
        selection.selectionEditor = null;
        selection.toolbar = null;
    } 

}).on('start', evt => {
    // Stupid hack to prevent browser executing its own scroll and messing up multiselect
    document.querySelector(".allowed-selection-area").classList.add("stop-scrolling")
    removeAllSelections()
    selection.active = true;
}).on('stop', evt => {
    // Stupid hack to prevent browser executing its own scroll and messing up multiselect
    document.querySelector(".allowed-selection-area").classList.remove("stop-scrolling")
    selection.active = false;

    if (evt.selected.length !== 0 && selection.toolbar && selection.selectionEditor && !selection.selectionEditor.editorInfo.viewOnly){    
        selection.toolbar.showToolbarAt(evt.oe.x, evt.oe.y);
    } 
});
