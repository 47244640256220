import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { executePromiseMinTime } from '../../logic/util'
import './ConfirmModal.scss'
import './Modal.scss'

class ConfirmModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = { isLoading: false }
        this.confirmClick = this.confirmClick.bind(this)
    }

    confirmClick() {
        if (this.props.data.includeLoading) {
            this.setState({isLoading: true});
            executePromiseMinTime(Promise.resolve(this.props.onConfirm()), 500).then(() => {
                this.setState({isLoading: false});
                this.props.onClose();
            });
        } else {
            if (this.props.onConfirm) this.props.onConfirm();
            this.props.onClose();
        }
    }

	render() {
        console.log("render confrirmodal")

		return (
            <Modal className="tw-modal confirm-modal" centered show={this.props.show} onHide={this.props.onClose} animation={false}>
                {this.props.data.modalIcon &&
                <div className="modal-icon">
                    {this.props.data.modalIcon}
                </div>}
                <h5 className="modal-title my-2">{this.props.data.title}</h5>
                <p className="footnote">{this.props.data.body}</p>
                <div className="mt-4 mb-2 mx-3 d-flex">
                    <div className="ml-auto">

                    </div>
                     {!this.props.data.hideClose && 
                     <Button disabled={this.state.isLoading} className="mr-3" variant="outline-primary" onClick={this.props.onClose}>
                        Cancel
                    </Button>}
                    {!this.props.data.hideConfirm && 
                    <Button disabled={this.state.isLoading} variant="primary" onClick={this.confirmClick}>
                        <span className="confirm-text">{this.props.data.confirmText ? this.props.data.confirmText : "OK"}</span>
                        <div class="loader">Loading...</div>
                    </Button>}
                </div>
            </Modal>
		);
	}
}

export default ConfirmModal;