import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { executePromiseMinTime } from '../../logic/util'
import { documentAPI } from '../../logic/api'
import './CancelSubscriptionModal.scss'
import './Modal.scss'
import { addInfoToast, addErrorToast } from '../ToastManager'
import ConfirmModal from './ConfirmModal'

class CancelSubscriptionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            currentStage: 1,
        }

        this.cancelSub = this.cancelSub.bind(this)
    }

    cancelSub(reason, txt) {
        documentAPI.cancelSubscription(this.props.data.currentWorkspaceID, { reason, txt }).then(() => {
            this.props.onConfirm();
            this.setState({currentStage:3})
        }).catch(() => {
            addErrorToast("Something went wrong")
            this.props.onConfirm();
        })
    }

    render() {
        return (
            <Modal className="tw-modal cancel-sub" centered show={true || this.props.show} onHide={this.props.onClose} animation={false}>
                <div className="modal-icon">
                    <i class="fas fa-ban"></i>
                </div>
                {this.state.currentStage === 1 && <ContinuCancel continue={()=> {this.setState({currentStage:2})}} cancel={this.props.onClose} endDate={this.props.data.endDate} />}
                {this.state.currentStage === 2 && <ConfirmCancel cancel={this.props.onClose} cancelSub={this.cancelSub} />}
                {this.state.currentStage === 3 && <ThankYou cancel={this.props.onClose}/>}
            </Modal>
		);
	}
}

CancelSubscriptionModal.defaultProps = {
    data: {}
};

export default CancelSubscriptionModal;


function ContinuCancel(props) {
    return (<>
        <h5 className="modal-title my-2">Cancel subscription</h5>
        <p className="footnote">
            Are you sure you want to cancel subscription?<br></br>
            You’ll continue to have access to the product until <br></br>{props.endDate}</p>
        <div className="mt-4 mb-2 mx-3 d-flex">
                <Button className="mr-3 ml-auto" variant="outline-primary" onClick={props.continue}>
                    Continue with cancellation
                </Button>
                <Button variant="primary" onClick={props.cancel} >
                    Cancel
                </Button>
        </div>
    </>)
}

function ThankYou(props) {
        return (<>
            <h5 className="modal-title my-2">Subscription cancelled</h5>
            <p className="footnote">
            Thank you. Your subscription is now canceled. <br></br>
            You can re-activate it anytime
            </p>
            <div className="mt-4 mb-2 mx-3 d-flex">
                <Button  className="ml-auto" variant="primary" onClick={props.cancel} >
                    Continue
                </Button>
            </div>
        </>)
}


function ConfirmCancel(props) {
    const [currentReason, setCurrentReason] = useState("");
    const [customReason, setCustomReason] = useState("");

    return (<>
        <h5 className="modal-title my-2">Cancel subscription</h5>
        <p className="footnote">We’re sorry to see you go. Why do you want to cancel:</p>
        <Form.Group>
            <Col sm={10}>
                <Form.Check custom type="radio" value="NOT_USING_ENOUGH" id="cancel-reason-1" className="cancel-reason" name="sub-cancel-reasons"
                    label="I’m not using it enough" onChange={(e) => {setCurrentReason(e.target.value)}} />
                <Form.Check custom type="radio" value="TOO_EXPENSIVE" id="cancel-reason-2" className="cancel-reason" name="sub-cancel-reasons"
                    label="It’s too expensive" onChange={(e) => {setCurrentReason(e.target.value)}} />
                <Form.Check custom type="radio" value="NEEDED_FOR_PERIOD" id="cancel-reason-3" className="cancel-reason" name="sub-cancel-reasons"
                    label="I needed translation only for a certain period" onChange={(e) => {setCurrentReason(e.target.value)}} />
                <Form.Check custom type="radio" value="SWITCHING_TO_OTHER" id="cancel-reason-4" className="cancel-reason" name="sub-cancel-reasons"
                    label="I’ve decided to use another translation service" onChange={(e) => {setCurrentReason(e.target.value)}} />
                <Form.Check custom type="radio" value="OTHER_REASON" id="cancel-reason-5" className="cancel-reason" name="sub-cancel-reasons"
                    label="Other" onChange={(e) => {setCurrentReason(e.target.value)}} />
            </Col>
        </Form.Group>
        <Form.Group controlId="formHorizontalPassword">
            <Col sm={12}>
                <Form.Control type="text" placeholder="Other reason" disabled={currentReason !== "cancel-reason-5"}  value={customReason} onChange={e => setCustomReason(e.target.value)}/>
            </Col>
        </Form.Group>
        <div className="mt-4 mb-2 mx-3 d-flex">
            <Button className="ml-auto mr-3" variant="outline-primary" disabled={currentReason === ""} onClick={() => {props.cancelSub(currentReason, customReason)}}>
                Confirm cancellation
            </Button>
            <Button variant="primary" onClick={props.cancel}>
                Cancel
            </Button>
        </div>
    </>)
}