import React, { useState } from "react";
import { Link } from "react-router-dom"
import logo from '../resources/main-logo.svg'
import login from '../resources/side-banner.svg'
import { documentAPI } from '../logic/api'
import { isEmail, checkPassword } from '../logic/util'
import Form from 'react-bootstrap/Form'

import './SignUp.scss'

export default function SignUp(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [errors, setErrors] = useState({});
    const [confirmSent, setConfirmSent] = useState(false);

    function validateForm() {
        var validated = true;
        let tmpErrors = {}

        if (email.length < 1) {
            tmpErrors = Object.assign(tmpErrors, { email: "Email is required" })
            validated = false;
        } else if (!isEmail(email)) {
            tmpErrors = Object.assign(tmpErrors, { email: "Valid email is required" })
            validated = false;
        }

        if (name.length < 1) {
            tmpErrors = Object.assign(tmpErrors, { name: "Name is required" })
            validated = false;
        }

        if (password.length < 1) {
            tmpErrors = Object.assign(tmpErrors, { password: "Password is required" })
            validated = false;
        } else if (!checkPassword(password)) {
            tmpErrors = Object.assign(tmpErrors, { password: "Password must match requirements" })
            validated = false;
        }

        if (!validated) setErrors(tmpErrors)
        return validated
    }

    function handleSubmit(event) {
        event.preventDefault();
        if (validateForm()) {
            documentAPI.signup({ email, name, password }).then(response => {
                setConfirmSent(true);
            }).catch(err => {
                if (err.status_code) {
                    if (err.status_code === "EMAIL_TAKEN") {
                        setErrors({ email: "Email taken" })
                    } else if (err.status_code === "INVALID_PASSWORD") {
                        setErrors({ password: "Password must match requirements" })
                    } else if (err.status_code === "EMAIL_UNVERIFIED") {
                        setErrors({ email: "Email unverified" })
                    } else {
                        setErrors({ general: "Something went wrong" })
                    }
                } else {
                    setErrors({ general: "Something went wrong" })
                }
            });
        }
    }

    return (
        <div className="sign-up-view row" style={{ width: "100vw", minHeight: "100vh" }}>
            <div className="col-6" style={{ background: "#f5f6f8" }}>
                <div style={{ height: "heigth:200px", margin: "32px 48px" }}><img className="main-logo" src={logo} alt="" /></div>
                <div className="side-logo"><img src={login} alt="" /></div>
            </div>
            <div className="col-6">
                <div style={{ height: "heigth:200px", margin: "32px 48px", float: "right" }}>
                    <Link to="/sign-in" className="df-btn auth-switch">Sign-in</Link>
                </div>
                <div className="form-wrap">
                    <div className="auth-form" style={{ margin: "auto", paddingBottom: "65px" }}>
                        <div style={{ marginTop: "-50px", maxWidth: "420px" }}>
                            {!confirmSent &&
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <h1 className="login-title">Let’s get started!</h1>
                                    <p className="login-details mb-0">Sign up and start translating.</p>
                                    <p className="general-error mb-5">{errors.general}</p>
                                    <div className="mx-auto text-left" style={{ width: "75%" }}>
                                        <Form.Group>
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control type="text" placeholder="Enter email" onChange={e => setEmail(e.target.value)} />
                                            <Form.Text className="error-text-sm">{errors.email}</Form.Text>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control type="text" placeholder="Name" onChange={e => setName(e.target.value)} maxLength={75} />
                                            <Form.Text className="error-text-sm">{errors.setName}</Form.Text>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                                            <Form.Text className="error-text-sm">{errors.password}</Form.Text>
                                        </Form.Group>
                                    </div>
                                    <div className="row password-requirements px-2 mt-3 mb-4">
                                        <div className="col-6">One lowercase character</div>
                                        <div className="col-6">One uppercase character</div>
                                        <div className="col-6">One number</div>
                                        <div className="col-6">6 characters minimum</div>
                                    </div>
                                    <button className="df-btn" style={{ marginTop: "32px" }}>Create account</button>
                                    <div className="sidelines"><span>OR</span></div>

                                    <div className="social-grid">
                                        <a href="/api/login/google" className="df-btn df-btn-inverse google">Google</a>
                                        <a href="/api/login/facebook" className="df-btn df-btn-inverse facebook">Facebook</a>
                                    </div>

                                </form>
                            }
                            {confirmSent &&
                                <>
                                    <h1 className="login-title">Email sent</h1>
                                    <p className="login-details mb-0">Please verify your email. You can find the verification link on your email</p>
                                </>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>);
}