import React, { useState } from "react";
import { Link } from "react-router-dom"
import logo from '../resources/main-logo.svg'
import login from '../resources/side-banner.svg'
import {documentAPI} from '../logic/api'
import { useHistory } from "react-router";
import { checkPassword } from '../logic/util'
import Form from 'react-bootstrap/Form'

export default function Recover(props) {
    let history = useHistory();
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
    const [errors, setErrors] = useState({});

    function validateForm() {
        var validated = true;
        let tmpErrors = {}

        if (password.length < 1) {
            tmpErrors = Object.assign(tmpErrors, { password: "Password is required"})
            validated = false;
        } else if (!checkPassword(password)) {
            tmpErrors = Object.assign(tmpErrors, { password: "Password must match requirements"})
            validated = false;
        }

        if (passwordAgain.length < 1) {
            tmpErrors = Object.assign(tmpErrors, { passwordAgain: "Password is required"})
            validated = false;
        } else if ( password !== passwordAgain ) {
            tmpErrors = Object.assign(tmpErrors, { passwordAgain: "Password must match"})
            validated = false;
        }

        setErrors(tmpErrors)
        return validated
    }

    function handleSubmit(event) {
        event.preventDefault();
        let code = new URLSearchParams(props.location.search).get("code")

        if (validateForm()) {
            documentAPI.recover(password, passwordAgain, code).then(() => {
                history.push("/sign-in");
            }).catch(err => {
                if (err.status_code && err.status_code === "NO_SUCH_TOKEN") {
                    setErrors({general:"Invalid password recover link"})
                } else if (err.status_code && err.status_code === "TOKEN_EXPIRED") {
                    setErrors({general:"Password recover link expired"})
                } else {
                    setErrors({general:"Something went wrong"})
                }
            })
        }
    }

    return (
        <div className="sign-up-view row" style={{width:"100vw", minHeight:"100vh"}}>
            <div className="col-6" style={{background:"#f5f6f8"}}>
                <div style={{height:"heigth:200px", margin:"32px 48px"}}><img src={logo} /></div>
                <div className="side-logo"><img src={login} /></div>
            </div>
            <div className="col-6">
                <div style={{height:"heigth:200px", margin:"32px 48px", float:"right"}}>
                    <Link to="/sign-in" className="df-btn auth-switch">Sign-in</Link>
                </div>
                <div  className="form-wrap">
                    <div className="auth-form" style={{margin:"auto", paddingBottom:"65px"}}>
                        <div style={{marginTop:"-50px", maxWidth: "460px"}}>
                            <form onSubmit={handleSubmit}>
                                <h1 className="login-title">Reset your password</h1>
                                <p className="login-details mb-0">Enter your new password.</p>
                                <p className="general-error mb-5">{errors.general}</p>
                                <div className="mx-auto text-left" style={{width:"75%"}}>
                                    <Form.Group>
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                                        <Form.Text className="error-text-sm">{errors.password}</Form.Text>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Password Again</Form.Label>
                                        <Form.Control type="password" placeholder="Password again" onChange={e => setPasswordAgain(e.target.value)}/>
                                        <Form.Text className="error-text-sm">{errors.passwordAgain}</Form.Text>
                                    </Form.Group>
                                </div>
                                <div className="row password-requirements px-2 mt-3 mb-4">
                                    <div className="col-6">One lowercase character</div>
                                    <div className="col-6">One uppercase character</div>
                                    <div className="col-6">One number</div>
                                    <div className="col-6">6 characters minimum</div>
                                </div>
                                <button className="df-btn" style={{marginTop:"32px"}}>Set password</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}