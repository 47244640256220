import React from 'react'
import './DefaultNavbar.scss';
import logo from '../resources/main-logo.svg'
import { extractInitials } from '../logic/util'

class DefaultNavbar extends React.Component {
    render() {
        return (
            <nav className="default-navbar navbar navbar-expand-md navbar-dark bg-white editor-navigation disable-select-box sticky-top">
                <div className="d-flex w-100 nav-wrap">
                    <div className="left-side d-flex">
                        <div className="mr-4 d-flex"><img className="main-logo" src={logo} /></div>
                        <span className="updated-at text-gray my-auto mr-3"></span>
                        <span className="editor-status text-gray my-auto"></span>
                    </div>
                    <UserPanel currentWorkspace={this.props.currentWorkspace} />
                </div>
            </nav>);
    }
}

export default DefaultNavbar;


function UserPanel(params) {
    let name = window.localStorage.getItem("fullName");
    return (
        <div className="user-panel dropdown show">
            <a className="dropdown-toggle d-flex" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                <span>
                    <p className="account-name mb-0" >{name}</p>
                    <span className="account-detail" >
                        {params.currentWorkspace.name}
                    </span>
                </span>
                <span className="avatar d-flex align-items-center justify-content-center">
                    {extractInitials(name)}
                </span>
            </a>
            <div className="dropdown-menu" id="user-menu" aria-labelledby="dropdownMenuLink">
                <a className="dropdown-item" href="/api/logout"><i className="far fa-sign-out-alt mr-2"></i> Log out</a>
            </div>
        </div>
    )
}