
class DocumentAPI {
    constructor() {
        this.hasMoreDocuments = true;
        this.documentPagination = {
            start: 40,
            resultsCount: 20,
            page: window.location.pathname,
        }
    }

    me() {
        return this._request("GET", "/api/me");
    }

    setInitPassword(data) {
        return this._request("POST", "/api/me/init-password", data);
    }

    changePassword(data) {
        return this._request("POST", "/api/me/password", data);
    }

    login(data) {
        return this._request("POST", "/api/sign-in", data);
    }

    signup(data) {
        return this._request("POST", "/api/sign-up", data);
    }

    forgot(email) {
        return this._request("POST", "/api/forgot", {email});
    }

    verify(token) {
        return this._request("POST", "/api/verify?token=" + token)
    }

    recover(password, password_again, code) {
        return this._request("POST", "/api/recover", {password, password_again, code});
    }

    workspaces() {
        return this._request("GET", "/api/workspaces")
    }

    newWorkspace(name) {
        return this._request("POST", "/api/workspaces", {name})
    }

    deleteWorkspace(ws) {
        return this._request("DELETE", "/api/workspaces/" + ws)
    }

    newDocument(ws) {
        return this._request("POST", "/api/workspaces/" + ws + "/documents")
    }

    documents(ws, filter) {
        return this._request("GET", "/api/workspaces/" + ws + "/documents?" + asQueryString(filter))
    }

    getDocument(ws, docID) {
        return this._request("GET", "/api/workspaces/" + ws + "/documents/" + docID)
    }

    deleteDocument(ws, docID) {
        return this._request("DELETE", "/api/workspaces/" + ws + "/documents/" + docID)
    }

    getDocumentUsers(ws, docID) {
        return this._request("GET", "/api/workspaces/" + ws + "/documents/" + docID + "/users")
    }

    addDocumentUser(ws, docID, shareData) {
        return this._request("POST", "/api/workspaces/" + ws + "/documents/" + docID + "/users", shareData)
    }

    getWorkspaceUsers(ws) {
        return this._request("GET", "/api/workspaces/" + ws + "/users")
    }

    addWorkspaceUser(ws, shareData) {
        return this._request("POST", "/api/workspaces/" + ws + "/users", shareData)
    }

    changeWorkspaceUser(ws, shareData) {
        return this._request("POST", "/api/workspaces/" + ws + "/users-change", shareData)
    }
    
    deleteWorkspaceUser(ws, userID) {
        return this._request("DELETE", "/api/workspaces/" + ws + "/users/" + userID)
    }

    leaveWorkspace(ws) {
        return this._request("POST", "/api/workspaces/" + ws + "/leave")
    }

    startSubscription(ws, data) {
        return this._request("POST", "/api/subscriptions/workspaces/" + ws , data)
    }

    checkSubscription(ws) {
        return this._request("POST", "/api/subscriptions/workspaces/" + ws + "/check")
    }

    reActivateSubscription(ws, data) {
        return this._request("POST", "/api/subscriptions/workspaces/" + ws + "/reactivate")
    }

    cancelSubscription(ws, data) {
        return this._request("DELETE", "/api/subscriptions/workspaces/" + ws, data)
    }

    getSubscriptionPrices() {
        return this._request("GET", "/api/subscriptions/prices")
    }

    _request(method, link, data, options) {
        if (!options) options = {}
        let headers = {}

        if (!options.contentTypeEmpty)
            headers["Content-Type"] = "application/json; charset=utf-8";
       
        var body = (method !== "GET" && data && !options.rawData) ? JSON.stringify(data) : (options.rawData ? data : null);
        return fetch(link, { method, body , headers })
            .then(res => {
                if (res.status === 403) {
                    // No auth
                    window.location.href = "/sign-in";
                } else if (res.status === 503) {
                    // Maintenance. Reload page so nginx shows maintenance page (query param to force reload)
                    window.location = window.location.href + '?c=1';
                } else {
                    return res
                }

                throw res
            })
            .then(res => res.json()).then(response => {
                if(response.status === "OK") {
                    return response.data
                } else {
                    throw response
                }
            })
    }
}

function asQueryString(params) {
    let urlParams = new URLSearchParams(Object.entries(params));
    return urlParams.toString();
}

export const documentAPI = new DocumentAPI();