import React from 'react'
import { Link } from "react-router-dom"
import { withRouter } from "react-router";
import { documentAPI } from '../logic/api'
import { extractInitials } from '../logic/util'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import './Sidebar.scss';


class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.newWorkspaceClick = this.newWorkspaceClick.bind(this);
    }

    newWorkspaceClick() {
        documentAPI.newWorkspace().then(newWorkspace => {
            this.props.history.push("/workspace/" + newWorkspace.id + "/");
        }).catch(e => {
            console.log("Workspace err", e)
        })
    }


    render() {
        let workspaces = this.props.workspaces ? this.props.workspaces.map((el) => (
            <OverlayTrigger transition={false} key={el.workspace.id} placement="right" overlay={<Tooltip>{el.workspace.name}</Tooltip>}>
                <Link to={"/workspace/" + el.workspace.id + "/"} className={el.workspace.id === parseInt(this.props.match.params.ws) ? "workspace-link active" : "workspace-link"}>{extractInitials(el.workspace.name)}</Link>
            </OverlayTrigger>
        )) : null;

        // TODO Settings link dynamic
        return (
            <div className="side-bar">
                <div className="side-slider">
                    {workspaces}
                    <OverlayTrigger transition={false} placement="right" overlay={<Tooltip>New workspace</Tooltip>}>
                        <Link className="add-workspace" to="/workspace/new"><i class="fas fa-layer-plus"></i></Link>
                    </OverlayTrigger>
                </div>
                <div className="bottom-extra d-flex flex-column" style={{ margin: "auto", marginBottom: "25px" }}>
                    <Link to="./settings" className="mb-2 mx-auto" >
                        <i className="fal fa-cog"></i>
                    </Link>
                    <a href="https://www.translatewise.com/et/translation" className="mx-auto" target="_blank" rel="noreferrer">
                        <i className="fal fa-question-circle"></i>
                    </a>
                </div>
            </div>);
    }
}

export default withRouter(Sidebar);
