import React from 'react'
import Toast from 'react-bootstrap/Toast'
import {genUUID} from '../logic/util'
import './Toast.scss'

export let addInfoToast;
export let addErrorToast;

export default class ToastContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            toasts: []
        }

        addInfoToast = (text) => {
            this.setState(previousState => ({
                toasts: [...previousState.toasts, {id:genUUID(), text:text, type:"info"}]
            }));
        }

        addErrorToast = (text) => {
            this.setState(previousState => ({
                toasts: [...previousState.toasts, {id:genUUID(), text:text, type:"warning"}]
            }));
        }

        this.removeToast = this.removeToast.bind(this)
    }

    componentDidMount() {
        
    }

    removeToast(index) {
        var array = [...this.state.toasts]; // make a separate copy of the array
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({toasts: array});
        }
    }

    render() {
        let toasts = this.state.toasts.map((el, i) => ( 
            el.type === "info" ? 
            <InfoAlert onDelete={this.removeToast} text={el.text} key={el.id} id={el.id} /> :
            <ErrorAlert onDelete={this.removeToast} text={el.text} key={el.id} id={el.id} />
        ))

        return (
            <div aria-live="polite" aria-atomic="true" className="toasts-container">
                <div className="toasts-list">
                   {toasts}
                </div>
            </div>)
    }
}

function ErrorAlert(params) {
    return (
        <Toast className="tw-toast d-flex" onClose={() => params.onDelete(params.id)} delay={4500} autohide>
            <i className="fad fa-exclamation-circle my-auto fa-error"></i>
            <div className="toast-body my-auto">{params.text}</div>
        </Toast>
    )
}


function InfoAlert(params) {
    return (
        <Toast className="tw-toast d-flex" onClose={() => params.onDelete(params.id)} delay={4500} autohide>
        <i className="fad fa-info-circle my-auto fa-success"></i>
            <div className="toast-body my-auto">{params.text}</div>
        </Toast>
    )
}