import AlignerText from './text-element'
import { genUUID, getBeginning, htmlDecode } from '../../util'

const MAX_TITLE_LEN = 100;

class AlignerTitle extends AlignerText {
    constructor() {
        super();

        // If this element is for new document we do not add listeners to it
        // as it will be recreated once document is created

        this.content = this.querySelector("content")
        this.addEventListener('keydown', this.keydownHandler.bind(this));
        this.addEventListener('input', this.inputHandler.bind(this));
        this.addEventListener('paste', this.pasteHandler.bind(this));

    }

    // Override 
    setContent(text, omitEvent) {
        let textPlain = document.createRange().createContextualFragment(text).textContent
        this.querySelector("content").innerHTML = textPlain;
        if (!omitEvent) this.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }));
    }

    keydownHandler(e) {
        if (![37, 38, 39, 40, 13].includes(e.keyCode)) e.stopPropagation();

        if (e.keyCode === 13) {
            e.stopPropagation()
            e.preventDefault();
            this.nextElementSibling.addCursor(true);
            return;
        }

        // Prevent typeing after 100 characters
        if (this.content.textContent.length >= MAX_TITLE_LEN &&
            (e.keyCode !== 8 && e.keyCode !== 37 && e.keyCode !== 38 && e.keyCode !== 39 && e.keyCode !== 40)) {
            e.preventDefault();
        }

    }

    inputHandler(e) {
        e.stopPropagation();
        this.parentElement.triggerHandler("onTitleUpdate", { newTitle: this.querySelector("content").textContent });
    }

    pasteHandler(e) {
        e.preventDefault();
        var clipboardData = (e.originalEvent || e).clipboardData;
        let text = clipboardData.getData("text/plain").trim()

        if (this.parentElement.unstartedDocument) {

            // Has single text
            if (text.indexOf("\n") === -1) {
                this.querySelector("content").innerHTML = text.substring(0, MAX_TITLE_LEN).removeUnknownCharacters()
                this.addCursor(false)
                e.stopPropagation()
            } else {
                let firstNewline = text.indexOf("\n")
                let len = firstNewline > MAX_TITLE_LEN ? MAX_TITLE_LEN : firstNewline;
                this.querySelector("content").innerHTML = getBeginning(text.substring(0, len)).removeUnknownCharacters()
                this.nextElementSibling.addCursor(false); // We set cursor to start of body so the editor picks up paste event again
                Object.defineProperty(e, 'target', { writable: false, value: this.nextElementSibling }); // Hacky solution to change target of the paste event
            }

        } else {
            if (text.indexOf("\n") === -1) {
                let data = clipboardData.getData("text/plain").removeUnknownCharacters().substring(0, 100);
                // Todo limit to 50 characters
                window.getSelection().getRangeAt(0).deleteContents();
                window.getSelection().getRangeAt(0).insertNode(document.createTextNode(data));
                window.getSelection().getRangeAt(0).collapse(false);
                e.stopPropagation();
            }
            // If It is multislext then 
        }

        this.normalize();
        this.parentElement.triggerHandler("onTitleUpdate", { newTitle: this.querySelector("content").textContent });
    }

}

AlignerTitle.template = (title, readOnly) => `
    <ali-title class="disable-select-box"  ali-rid="TITLE" ali-id="${genUUID()}" >
        <content contenteditable="${readOnly ? "false" : "true"}" spellcheck="false" placeholder="Enter title">${title ? title : ""}</content>
        <div class="extra">
            <div class="update-translation" title="Update translation">
                <div class="inner-circle">
                    <i class="fas fa-language"></i><div class="loader">Loading...</div>
                </div>
            </div>
        </div>
    </ali-title>`

if (!window.customElements.get("ali-title"))
    customElements.define('ali-title', AlignerTitle);

export default AlignerTitle;