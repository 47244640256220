import React from 'react'
import EditorNavbar from '../components/EditorNavbar'
import TextEditor from '../components/TextEditor'
import EditorRelated from '../components/EditorRelated'
import DualEditorEventManager from '../logic/editor/dualEditorEvents';
import './Editor.scss'
import ConfirmModal from '../components/modals/ConfirmModal'
import { startSelectionListeners, stopSelectionListeners} from '../logic/selection'

class DualEditor extends React.Component {
    constructor(props){
        super(props);
        this.eventManager = new DualEditorEventManager(this.props.match.params.ws, !this.props.match.params.doc);
    }

    componentDidMount() {
        startSelectionListeners();
        
        if (this.props.match.params.doc && this.props.match.params.doc !== "new") {
            var { doc } = this.props.match.params;
            //TODO mounting order
            
            this.eventManager.startDocumentSession(doc)
        }
    }

    componentWillUnmount() {
        this.eventManager.ws.close();
        stopSelectionListeners();
    }

    render() {
      return (
        <div className="dual-text-editor">
            <EditorNavbar isNewPage={!this.props.match.params.doc} eventManager={this.eventManager} match={this.props.match} />
            <div className="allowed-selection-area"> 
                <div className="row pane-main-container position-relative unstarted-document">
                    <div className="col-6 pane-container">
                        <TextEditor isNewPage={!this.props.match.params.doc} masterEditor={true} eventManager={this.eventManager} />
                    </div>
                    <div className="col-6 pane-container">
                        <EditorRelated isNewPage={!this.props.match.params.doc} eventManager={this.eventManager} />
                    </div>
                </div>
            </div>
            <DualEditorPopups eventManager={this.eventManager} />
        </div>);
    }
}

export default DualEditor;


class DualEditorPopups extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            showModal : false,
        }
    }

    componentDidMount() {
        this.props.eventManager.on("onConnetionLost", (() => {
            this.setState({showModal: true})
        }))

        this.props.eventManager.on("onOffline",(() => {
            this.setState({showModal: true})
        }))
    }

    render() {
        return (
            <>
              { this.state.showModal &&
                <ConfirmModal 
                    show={this.state.showModal} 
                    onClose={() => {}} 
                    onConfirm={() => { document.location.reload(); }} 
                    data={{
                        title:"Connection lost",
                        body:"Seems that we have lost connection to the document",
                        hideClose: true,
                        confirmText: "Reload",
                    }}/> 
              }  
            </>
        )
    }
}