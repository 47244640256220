import { useEffect } from 'react'
import ListView from './pages/ListView'
import DualEditor from './pages/DualEditor'
import SignUp from './pages/SignUp'
import Settings from './pages/Settings'
import SignIn from './pages/SignIn'
import NewWorkspace from './pages/NewWorkspace'
import Forgot from './pages/Forgot'
import ToastContainer from './components/ToastManager'
import { b64DecodeUnicode } from './logic/util'
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from "react-router-dom"
import { documentAPI } from './logic/api'
import Recover from './pages/Recover'
import PopupContainer from './components/PopupManager'

export default function App() {
  return (
    <div id="tw-app">
      <Router>
        <div>
          <Switch>
            <Route exact path="/sign-in" component={SignIn} />
            <Route exact path="/sign-up" component={SignUp} />
            <Route exact path="/recover" component={Recover} />
            <Route exact path="/forgot" component={Forgot} />
            <Route exact path="/confirm" component={ConfirmEmail} />

            <PrivateRoute exact path="/workspace/new" component={NewWorkspace} />
            <PrivateRoute exact path="/workspace/:ws" component={ListView} />
            <PrivateRoute exact path="/workspace/:ws/settings" component={Settings} />
            <PrivateRoute exact path="/workspace/:ws/documents/new" component={DualEditor} />
            <PrivateRoute exact path="/workspace/:ws/documents/:doc" component={DualEditor} />
            <PrivateRoute component={RedirectToExistingWS} />
          </Switch>
          <PopupContainer />
          <ToastContainer />
        </div>
      </Router>
    </div>
  );
}

function PrivateRoute({ component: Component, ...otherProps }) {
  let auth = document.cookie.match('(^|;)\\s*tw_sess\\s*=\\s*([^;]+)');

  if (auth) // TODO Better
    window.localStorage.setItem("fullName", b64DecodeUnicode(auth.pop()));

  return <Route {...otherProps} render={props => {
    return auth ? (<Component {...props} />) : <Redirect to={{ pathname: "/sign-in", state: { from: props.location } }} />
  }} />
}

function RedirectToExistingWS() {
  let history = useHistory();

  useEffect(() =>
    documentAPI.workspaces().then((workspaces) => {
      if (workspaces.length) {
        history.push("/workspace/" + workspaces[0].workspace_id + "/")
      } else {
        history.push("/workspace/new/")
      }
    }).catch(() => {
      history.push("/sign-in")
    }));

  return null;
}

function ConfirmEmail(props) {
  let history = useHistory();
  let code = new URLSearchParams(props.location.search).get("code")


  useEffect(() =>
    documentAPI.verify(code).then(response => {
      if (response.workspace) {
        history.push("/workspace/" + response.workspace.id + "/");
      } else {
        history.push("/workspace/new");
      }
    }).catch(() => {
      history.push("/sign-in")
    }));

  return null;
}