import React from 'react'
import CancelSubscriptionModal from './modals/CancelSubscriptionModal';
import ConfirmModal from './modals/ConfirmModal';
import OverFreeLimitModal from './modals/OverFreeLimitModal';
import SubscriptionModal from './modals/SubscriptionModal';

export let addAnyModal;
export let addErrorPopup;
export let addLockedErrorPopup;
export let addOverLimitPopup;
export let addSubscriptionPopup;
export let addCancelSubscriptionPopup;
export let addConfirmPopup;


export default class PopupContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            toasts: [],
            currentPopup: null,
        }

        this.removeCurrentPopup = this.removeCurrentPopup.bind(this)

        addAnyModal = (modal) => {
            this.setState({
                currentPopup:  React.cloneElement(modal, { onClose: this.removeCurrentPopup, show: true })
            });
        }

        addConfirmPopup = (data, onConfirm) => {
            this.setState({
                currentPopup:  <ConfirmModal show={true} onClose={this.removeCurrentPopup} onConfirm={onConfirm} data={data}/> 
            });
        }

        addErrorPopup = (data, onConfirm) => {
            this.setState({
                currentPopup:  <ConfirmModal show={true} onClose={this.removeCurrentPopup} onConfirm={() => {if(onConfirm) onConfirm(); this.removeCurrentPopup()}} data={data}/> 
            });
        }

        addLockedErrorPopup = (data, onConfirm) => {
            this.setState({
                currentPopup:  <ConfirmModal show={true} onConfirm={() => {if(onConfirm) onConfirm(); this.removeCurrentPopup()}} data={data}/> 
            });
        }

        addOverLimitPopup = (data) => {
            this.setState({
                currentPopup: <OverFreeLimitModal show={true} onClose={this.removeCurrentPopup} data={data} />
            })
        }

        addCancelSubscriptionPopup = (data, onConfirm) => {
            this.setState({
                currentPopup:  <CancelSubscriptionModal show={true}  onClose={this.removeCurrentPopup} onConfirm={onConfirm} data={data}/> 
            });
        }

        addSubscriptionPopup = (data, onConfirm) => {
            this.setState({
                currentPopup:  <SubscriptionModal show={true}  onClose={this.removeCurrentPopup} onConfirm={() => {if(onConfirm) onConfirm(); this.removeCurrentPopup()}} data={data}/> 
            });
        }
    }

    removeCurrentPopup() {
        this.setState({currentPopup: null})
    }

    render() {
        return <>{this.state.currentPopup}</>
    }
}