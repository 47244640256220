import React, { useState } from "react";
import { Link } from "react-router-dom"
import logo from '../resources/main-logo.svg'
import login from '../resources/side-banner.svg'
import { documentAPI } from '../logic/api'
import { useHistory } from "react-router";
import Form from 'react-bootstrap/Form'

import './SignIn.scss'

// Internet Explorer 6-11
const isIE = /*@cc_on!@*/false || !!document.documentMode;

export default function SignIn(props) {

    let history = useHistory();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({});

    function validateForm() {
        var validated = true;
        let tmpErrors = {}

        if (email.length < 1) {
            tmpErrors = Object.assign(tmpErrors, { email: "Email is required" })
            validated = false;
        }

        if (password.length < 1) {
            tmpErrors = Object.assign(tmpErrors, { password: "Password is required" })
            validated = false;
        }

        setErrors(tmpErrors)
        return validated
    }

    function handleSubmit(event) {
        event.preventDefault();

        if (validateForm()) {
            documentAPI.login({ email, password }).then(response => {
                if (response.workspace) {
                    history.push("/workspace/" + response.workspace.id + "/");
                } else {
                    history.push("/workspace/new");
                }
            }).catch(err => {
                if (err.status_code) {
                    if (err.status_code === "PWD_WRONG") {
                        setErrors({ password: "Wrong password" })
                    } else if (err.status_code === "NO_USER") {
                        setErrors({ email: "No such user" })
                    } else if (err.status_code === "EMAIL_UNVERIFIED") {
                        setErrors({ email: "Email unverified" })
                    } else {
                        setErrors({ general: "Something went wrong. Try again later." })
                    }
                } else {
                    setErrors({ general: "Something went wrong. Try again later." })
                }
            });
        }
    }


    if (isIE) {
    }

    return (
        <div className="sign-in-view row" style={{ width: "100vw", minHeight: "100vh" }}>
            <div className="col-6" style={{ background: "#f5f6f8" }}>
                <div style={{ height: "heigth:200px", margin: "32px 48px" }}><img className="main-logo" src={logo} alt="" /></div>
                <div className="side-logo" ><img src={login} alt="" /></div>
            </div>
            <div className="col-6">
                <div style={{ height: "heigth:200px", margin: "32px 48px", float: "right" }}>
                    <Link to="/sign-up" className="df-btn auth-switch">Sign-up</Link>
                </div>

                <div className="form-wrap">
                    <div className="auth-form" style={{ margin: "auto", paddingBottom: "65px" }}>
                        {!isIE &&
                            <div style={{ marginTop: "-50px", maxWidth: "420px" }}>
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <h1 className="login-title">Great to see you again.</h1>
                                    <p className="login-details mb-0">Access your account and keep on rocking!</p>
                                    <p className="general-error mb-5">{errors.general}</p>

                                    <div className="mx-auto text-left" style={{ width: "75%" }}>
                                        <Form.Group>
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control type="email" placeholder="Enter email" onChange={e => setEmail(e.target.value)} />
                                            <Form.Text className="error-text-sm">{errors.email}</Form.Text>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                                            <Form.Text className="error-text-sm">{errors.password}</Form.Text>
                                        </Form.Group>
                                    </div>
                                    <p><Link to="/forgot" className="forgot-password" style={{ marginTop: "auto" }}>Forgot password ?</Link></p>
                                    <button className="df-btn" style={{ marginTop: "22px" }}>Begin translating</button>
                                </form>

                                <div className="sidelines"><span>OR</span></div>

                                <div className="social-grid">
                                    <a href="/api/login/google" className="df-btn df-btn-inverse google">Google</a>
                                    <a href="/api/login/facebook" className="df-btn df-btn-inverse facebook">Facebook</a>
                                </div>

                                <p className="tos-agree-text">By clicking Log In, I confirm that I have read and agree to the TranslateWise Terms of Service, Privacy Policy, and to receive emails and updates.</p>
                            </div>}
                        {isIE &&
                            <div style={{ marginTop: "-50px", maxWidth: "380px" }}>
                                <h1 className="login-title">Sorry !</h1>
                                <p className="login-details mb-5">This browser is not supported.</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>);
}