import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import { documentAPI } from '../../logic/api'
import {withRouter} from 'react-router-dom';
import { addInfoToast, addErrorToast } from '../ToastManager'
import { isEmail } from './../../logic/util'

import './InviteUserModal.scss'
import './Modal.scss'

class InviteTeamUserModal extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
            selectedRole: "EDIT",
            email: "",
        }

        this.shareDocument = this.shareDocument.bind(this)
        this.handleEmailChange = this.handleEmailChange.bind(this)
    }

    handleEmailChange(event) {
        this.setState({email: event.target.value});
    }
    
    changeSelectedToView = () => this.setState({selectedRole:"VIEW"})
    changeSelectedToEdit = () => this.setState({selectedRole:"EDIT"})

    shareDocument() {
        let email = this.state.email;
        let role = this.state.selectedRole;

        if (email === "") {
            addErrorToast("Email is required")
            return
        }

        if (!isEmail(email)) {
            addErrorToast("A valid email is required")
            return
        }

        documentAPI.addWorkspaceUser(this.props.match.params.ws, {email, role}).then(r => {
            addInfoToast("User added")
            this.props.onInvited()
        }).catch(() => {
            addErrorToast("Something went wrong. Could not invite user")
        })
    }
    
	render() {
		return (
            <Modal className="tw-modal invite-user-modal" size="lg" centered onShow={() => this.setState({email:""})} show={this.props.show} onHide={this.props.onClose} animation={false}>
                <h5 className="modal-title my-2">Invite to team</h5>
                <p className="footnote">Invite a new member to your team</p>
                <div className="input-container d-flex mb-3">
                        <input id="share-email" type="email" placeholder="work@email.com" value={this.state.email} onChange={this.handleEmailChange}  />
                        <DropdownButton id="share-role-selector" title= {this.state.selectedRole === "VIEW" ? "Can view": "Can edit"}  menuAlign="right">
                            <Dropdown.Item onClick={this.changeSelectedToView}>Can view</Dropdown.Item>
                            <Dropdown.Item onClick={this.changeSelectedToEdit}>Can edit</Dropdown.Item>
                        </DropdownButton>
                        <Button className="ml-4 w-25" variant="primary" onClick={this.shareDocument}>
                            Send invite
                        </Button>
                        <small className="hint">
                            Email
                        </small>
                </div>
            </Modal>
		);
	}
}

export default withRouter(InviteTeamUserModal);