import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { addErrorToast } from "../ToastManager"
import { documentAPI } from "../../logic/api"
import './SubscriptionModal.scss'
import './Modal.scss'
import { Form, ToggleButton } from 'react-bootstrap';
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement
  } from "@stripe/react-stripe-js";
import { matchPath, withRouter } from 'react-router';
import { addConfirmPopup } from '../PopupManager';

const stripePublishableKey = window.location.host === "app.translatewise.com" ? "pk_live_mxv8ODqTCjRvpC3okRHmwttO" : "pk_test_wJceECUbGnjYOXTjcOGCCRZp";
const stripePromise = loadStripe(stripePublishableKey, { locale: 'en' });
const options = {
    style: {
      base: {
        color: "#424770",
        letterSpacing: "0.025em",
        fontFamily: "Source Code Pro, monospace",
        "::placeholder": {
          color: "#daddeb"
        }
      },
      invalid: {
        color: "#9e2146"
      }
    }
}
class SubscriptionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { period: "yearly" }
        this.setPeriod = this.setPeriod.bind(this)
    }

    setPeriod(period) {
        this.setState({period})
    }

	render() {
		return (
            <Modal className="tw-modal subscription-modal" size="md" centered show={this.props.show} onHide={this.props.onClose} animation={false}>
                <div className="modal-icon">
                    <i class="fad fa-credit-card"></i>
                </div>
                <div className="title">Choose Plan</div>
                <PlanChoice title="Pay monthly" price="€9.98" selected={this.state.period === "monthly"} onClick={() =>  {this.setPeriod("monthly")}}/>
                <PlanChoice title="Pay yearly" price="€4.99" selected={this.state.period === "yearly"} onClick={() =>  {this.setPeriod("yearly")}} isSale={true} />
                {this.state.period === "monthly" && <div className="billing">Billed monthly <span className="price">€9.98</span></div>}
                {this.state.period === "yearly" && <div className="billing">Billed annually <span className="price">€59,88</span></div>}
                <div className="grey-info">For both plans you'll get all the benefits. VAT included.</div>
                <Elements stripe={stripePromise} >
                    <StripeForm onConfirm={this.props.onConfirm} period={this.state.period}/>
                </Elements>
            </Modal>
		);
	}
}

export default SubscriptionModal;

function PlanChoice(params) {
    return(
        <div className={params.selected ? "choice selected" : "choice"} onClick={params.onClick}>
            {params.isSale && <div className="sale-tag">50% OFF</div>}
            <div className="d-flex flex-column">
                <span><b>{params.title}</b></span>
                <span className="price-line"><span className="price">{params.price}</span>/month</span>
            </div>
            {params.selected && 
            <div className="check-mark my-auto">
                <i class="fad fa-check-circle"></i>
            </div>}
        </div>
    )
}


const StripeForm = (props) => {
    const [proccessing, setProccessing] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const match = matchPath(window.location.pathname, { path: '/workspace/:ws_id/*' });
  
    const handleSubmit = event => {
      event.preventDefault();
  
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }
  
      if (!match || !match.params.ws_id) {
          return
      }
  
      setProccessing(true)

      stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement)
      }).then(result => {
          if (result.error) {
              addErrorToast(result.error.message);
              setProccessing(false)
            } else {
              documentAPI.startSubscription(match.params.ws_id, {
                  package: "PRO_PACK",
                  isYearly: props.period === "yearly",
                  paymentMethodId: result.paymentMethod.id,
                }).then((client_secret) => {
                    stripe.confirmCardPayment(client_secret).then((confirmResult) => {
                        if (confirmResult.error) {
                            addErrorToast(confirmResult.error.message);
                            setProccessing(false)
                        } else {
                            documentAPI.checkSubscription(match.params.ws_id).then((confirmResult) => {
                                setProccessing(false)
                                props.onConfirm();
                                if (confirmResult === "ACTIVE") {
                                    addConfirmPopup({
                                        modalIcon: <i class="fad fa-gem"></i>,
                                        title:"Your payment was successful!",
                                        body:"Payment information has been sent to your email. You can now continue translating. ",
                                        hideClose: true,
                                        confirmText: "Continue",
                                  })
                                } else {
                                     throw "Not active"
                                }  
                            }).catch((err) => {
                                setProccessing(false)
                                props.onConfirm();
                                addConfirmPopup({
                                    modalIcon: <i class="fad fa-gem"></i>,
                                    title:"Your payment was successful!",
                                    body:"Setting up your subscriptin seems to be taking couple of minutes. If subscription is not set in 5 minutes, please contact customer support.",
                                    hideClose: true,
                                    confirmText: "Continue",
                              })
                            })
                            
                        }
                    }).catch(e=> {
                        addErrorToast("Something went wrong. Try again later");
                        setProccessing(false)
                    })
              }).catch((err) => {
                setProccessing(false)
                if (err.status_code === "STRIPE_ERROR") {
                    addErrorToast(err.message);
                } else {
                    addErrorToast("Something went wrong. Try again later");
                }
              })
            }
      }).catch(e => {
          setProccessing(false)
          addErrorToast("Something went wrong. Try again later");
      });
    };

    return (
        <form onSubmit={handleSubmit}>
            <label>
                Card number
                    <CardNumberElement options={options} />
            </label>
            <div className="row">
                <label className="col">
                    Expiration date
                        <CardExpiryElement options={options} />
                </label>
                <label className="col">
                    CVC
                        <CardCvcElement options={options} />
                </label>
            </div>
            <div className="mx-auto my-3">
                <button type="submit" disabled={!stripe || proccessing}>
                    <span className="pay-text">Pay now</span>
                    <div class="loader">Loading...</div>
                </button>
            </div>
            <Form.Check type="checkbox" id="agree">
                <Form.Check.Input type="checkbox" required />
                <Form.Check.Label>I agree wiith TranslateWise <a className="agreement-link" href="https://www.translatewise.com/terms-of-use" target="_blank" rel="noreferrer">Terms of Use</a> and <a className="agreement-link" href="https://www.translatewise.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>.</Form.Check.Label>
            </Form.Check>
        </form>
    );
  };
  