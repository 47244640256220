import { documentAPI } from '../api'
import AlignerElement from './elements/core-element'
import EditorWS from './websocket'
import { genUUID, executePromiseMinTime } from '../util'
import { addErrorToast } from '../../components/ToastManager'
import { addErrorPopup, addOverLimitPopup } from '../../components/PopupManager'

const RTL_LANGS = ["ar", "fa", "ha", "he", "ku", "ps", "ur", "yi"];

export default class DualEditorEventManager {
    constructor(workspaceID, startedFromNew) {
        this.startedFromNew = startedFromNew;
        this.workspaceID = parseInt(workspaceID);
        this.ws = new EditorWS(this)
        this.masterEditor = null;
        this.relatedEditor = null;
        this.documentCollectionID = null;
        this.userRole = "";

        this.listeners = {}
        this.editorCallbacks = {
            "onAddFragment" : this.onAddFragment.bind(this),
            "onChangeContentFragment" : this.onChangeContentFragment.bind(this),
            "onTitleUpdate" : this.onTitleUpdate.bind(this),
            "onDeleteFragment" : this.onDeleteFragment.bind(this),
            "onFixFragment" : this.onFixFragment.bind(this),
            "onDetectLanguage" : this.detectLanguage.bind(this),
            "onTranslateContent" : this.translateContent.bind(this),
        }
    }

    on(event, cb) {
        if(this.listeners[event]) {
            this.listeners[event].push(cb)
        } else {
            this.listeners[event] = [cb]
        }
    }

    callListeners(event, ...args) {
        if(this.listeners[event])
            this.listeners[event].forEach( cb => cb(...args))
    }

    editorEventHandler(event, editor, data) {
        return this.editorCallbacks[event](editor, data)
    }

    startDocumentSession(documentID) {
        this.ws.connect(documentID)
        this.callListeners("onDocumentConnected", documentID, false)
    }

    createNewDocument(cb) {
        var self = this;

        documentAPI.newDocument(this.workspaceID).then(r => {
            window.history.replaceState({},"", r.id);
            self.ws.connect(r.id)
            this.masterEditor.documentID = r.document_languages[0].key
            self.callListeners("onCreateNewDocument", r)
            self.callListeners("onDocumentConnected", r.id, true)
            if (cb) cb();
        }).catch(e => {
            // TTODO
        })
    }


    /* 
        Editor event handlers
    */

    onAddFragment (editor, eventData) {
        if (editor.unstartedDocument) {
            editor.unstartedDocument = false;
            this.createNewDocument();
        }

        eventData.element.setAttribute("state", "new")

        // If element was first placeholder
        if (eventData.element.getAttribute("ali-empty")) {
            eventData.element.removeAttribute("ali-empty")
            eventData.element.removeAttribute("ali-dummy")

            // If we have related editor we set the first element to dummy
            let op = eventData.element.getOppositeElement();
            if (op) {
                eventData.element.removeAttribute("ali-empty")
                eventData.element.setAttribute("ali-dummy", true)
            }
        } else {
            var dummy = AlignerElement.asNode(AlignerElement.dummyElement(eventData.element.getAttribute("ali-type"), eventData.element.getAttribute("ali-rid")));
            var oppositeEditor = this._getOppositeEditor(editor)
            if (oppositeEditor.documentID)
                oppositeEditor.insertChildAtIndex(dummy, eventData.position)
        }

        this._saveWithDebounce(editor);
    }

    onChangeContentFragment (editor, eventData) {
        if (editor.unstartedDocument) {
            editor.unstartedDocument = false;
            this.createNewDocument();
        }

        if (!eventData.element.getAttribute("state"))
            eventData.element.setAttribute("state", "updated")

        this._saveWithDebounce(editor);
    }

    onFixFragment(editor, eventData){
        eventData.element.removeAttribute("ali-dummy")
        if(!eventData.element.getAttribute("state")) eventData.element.setAttribute("state", "fixed");
        this._saveWithDebounce(editor);
    }

    onTitleUpdate(editor, eventData) {
        if (editor.unstartedDocument) {
            editor.unstartedDocument = false;

            this.createNewDocument(() => {
                this.ws.sendEvent({
                    requestID: genUUID(), 
                    targetDocID: editor.documentID, 
                    event: "updateTitle",
                    data: eventData,
                })
            });
            return;
        }

        this.ws.sendEvent({
            requestID: genUUID(), 
            targetDocID: editor.documentID, 
            event: "updateTitle",
            data: eventData,
        })
    }

    onDeleteFragment(editor, eventData) {
        if (eventData.element.getAttribute("ali-empty")) return // First empty row cannot be deleted

        editor.deleted.push(eventData.element.getAttribute("ali-id"))
        this._saveWithDebounce(editor);

        return this._testIfFragmentCanBeDeleted(editor, this._getOppositeEditor(editor), eventData.element)
    }

    _testIfFragmentCanBeDeleted(eventEditor, otherEditor, fragment) {
        var otherFragment = fragment.getOppositeElement()

        // If deleting last element
        if (eventEditor.querySelectorAll("ali-txt").length === 1) {
            fragment.setAttribute("ali-empty", true)
            fragment.setAttribute("ali-type", "p")
            fragment.setAttribute("ali-rid", genUUID())
            fragment.setAttribute("ali-id", genUUID())

            if (otherFragment) {
                otherFragment.setAttribute("ali-empty", true)
                otherFragment.setAttribute("ali-type", "p")
                otherFragment.setAttribute("ali-rid", fragment.getAttribute("ali-rid"))
            }

            return false
        } 

        if (!otherFragment) return true; // No related fragment found. Can delete

        if (otherFragment.getAttribute("ali-dummy")) {
            otherFragment.remove();
            return true;
        }

        return false;
    }

    /* 
        Events called straight from eventmanagers
    */

    changeLanguage(languageDocKey) {
        this.ws.sendEvent({
            event: "changeLanguage",
            data: { languageDocKey },
        })
    }

    addLanguage(data){
        let role = this.userRole
        this.ws.sendEventWithCB({
            event: "addLanguage",
            data: data,
        }).catch((error) => {
            if (error.status_code === "TEXT_TOO_LONG") {
                addErrorPopup({
                    title: "Document too long",
                    body: "Document can not exceed 15000 characters",
                    hideClose: true,
                })
            } else if (error.status_code === "TRANSLATION_LIMIT") {
                addOverLimitPopup({subscriptionLink: "../settings#subscription", role})
            } else {
                addErrorToast("Something went wrong. Unable to translate language")
            }
        });
    }

    deleteLanguage(eventData) {
        this.ws.sendEvent({
            event: "deleteLanguage",
            data: eventData,
        })
    }

    setMasterLanguage(eventData) {
        this.ws.sendEventWithCB({
            event: "setLanguage",
            data: eventData,
        }).then(response => {    
            this.callListeners("onMasterLanguageDetected", response.data)
        })
    }

    detectLanguage(editor, eventData) {
        this.ws.sendEventWithCB({
            event: "detectLanguage",
            data: eventData,
        }).then(response => {
            this.callListeners("onMasterLanguageDetected", { language: response.data.eng })
        })
    }

    translateContent(editor, eventData) {
        let role = this.userRole
        executePromiseMinTime(this.ws.sendEventWithCB({
            event: "translateContent",
            targetDocID: editor.documentID, 
            data: { text: eventData.text },
        }), 750).then((response) => {
            // this.callListeners("onMasterLanguageDetected", { language: response.data.eng })
            let text = response.data ? response.data : "";
            eventData.element.setContent(text)
            eventData.element.addCursor(false)
            eventData.element.querySelector(".update-translation").classList.remove("loading")
         }).catch(e => {
             eventData.element.querySelector(".update-translation").classList.remove("loading")
             if (e.status_code === "TRANSLATION_LIMIT") {
                addOverLimitPopup({subscriptionLink: "../settings#subscription", role})
             } else {
                addErrorToast("Could not translate")
             }
         })
    }

    /*
        Websocket messages
    */

    incomingMessage(message) {
        switch (message.event) {
            case "init":
                let isViewOnly = !["OWNER", "EDIT"].includes(message.data.documentRole)
                this._setDocQueryParam(message.data.relatedLanguage)
                this.userRole = message.data.documentRole
                this.callListeners("onActiveUsersChange", message.data.users)
                this.callListeners("onTranslationCountChange", message.data.translationLimit, message.data.translationLimitMax, message.data.documentRole, message.data.isProPack)
                this.callListeners("onLanguagesChange", message.data.documentInfo.document_languages.filter(language => language.key !== message.data.masterLanguage))
                this.documentCollectionID = parseInt(message.data.documentInfo.id)

                if (!this.startedFromNew) {
                    let masterDocID = message.data.masterLanguage
                    let relatedDocID = message.data.relatedLanguage;
                    let masterInfo = message.data.documentInfo.document_languages.find(language => language.key === masterDocID)
                    let relatedInfo = message.data.documentInfo.document_languages.find(language => language.key === relatedDocID)
                    let masterLanguageDoc = message.data.document.language_docs[masterDocID]
                    let relatedLanguageDoc = message.data.document.language_docs[relatedDocID]
                    let frags = this.constructLanguagePairFragments(message.data.document.matrix, masterLanguageDoc, relatedLanguageDoc)
                    this.masterEditor.documentID = masterDocID
                    this.masterEditor.setDocumentInfo(masterInfo, message.data.documentRole, isViewOnly)
                    this.masterEditor.loadContent(frags.master, masterLanguageDoc.title, null, isViewOnly)
                    this.relatedEditor.setDocumentInfo(relatedInfo, message.data.documentRole, isViewOnly)

                    if (relatedDocID)  {
                        let isRTL = RTL_LANGS.includes(relatedInfo.language_code);
                        this.relatedEditor.documentID = relatedDocID
                        this.relatedEditor.loadContent(frags.related, (relatedLanguageDoc ? relatedLanguageDoc.title: ""), null, isViewOnly, isRTL)
                    }


                    this.callListeners("onRelatedEditorLoaded", relatedInfo, isViewOnly)
                    this.callListeners("onMasterEditorLoaded", masterInfo, isViewOnly )
                }

                this._startAutoAligner();
                this.startedFromNew = false;
                break;
            case "translationCount":
                this.callListeners("onTranslationCountChange", message.data.translationLimit, message.data.translationLimitMax)
                break;
            case "userJoin":
            case "userLeave":
                this.callListeners("onActiveUsersChange", message.data.users)
                break;

            case "updateTitle":
                var editor = null
                if(this.masterEditor.documentID === message.targetDocID) editor = this.masterEditor
                if(this.relatedEditor.documentID === message.targetDocID) editor = this.relatedEditor
                if (editor) editor.querySelector("ali-title").setContent(message.data.newTitle, true)
                
                break;
            // Add language is called when other person adds language
            case "addLanguage":
                this.callListeners("onLanguagesChange", message.data.documentInfo.document_languages.filter(language => language.key !== this.masterEditor.documentID))
                break;
            case "deleteLanguage":
                if (this.relatedEditor.documentID === message.data.deletedLanguage) {
                        this.relatedEditor.documentID = ""
                        this.relatedEditor.innerHTML = ""
                        this.callListeners("onRelatedEditorLoaded", { key: "" }, this.relatedEditor.editorInfo.viewOnly)
                }

                this.callListeners("onLanguagesChange", message.data.documentInfo.document_languages.filter(language => language.key !== this.masterEditor.documentID))

                break;
            case "merge":
                this.handleWSMerge(message.targetDocID, message.data);
                this.callListeners("collaborationMerge");
                break;

            //no default
        }
    }

    handleWSMerge(targetDocID, data) {

        var editor = null
        if(this.masterEditor.documentID === targetDocID) editor = this.masterEditor
        if(this.relatedEditor.documentID === targetDocID) editor = this.relatedEditor

        // Only when merge is with an current documents
        if (editor) {
            var otherEditor = this._getOppositeEditor(editor)

            if (data.deleted) {
                data.deleted.forEach((elemID) => {
                    let deletedFrag = editor.getFragmentByID(elemID)
                    if (deletedFrag) {
                        if (this._testIfFragmentCanBeDeleted(editor, otherEditor, deletedFrag)){
                            deletedFrag.remove();
                        } else {
                            deletedFrag.setContent("", true);
                            deletedFrag.setAttribute("ali-dummy", true)
                        }
                    }
                })
            }

            if (data.deleted) {
                data.rows_deleted.forEach((rid) => {
                    editor.querySelector(`[ali-rid="${rid}"]`).remove()
                    otherEditor.querySelector(`[ali-rid="${rid}"]`).remove()
                })
            }

            var lastFragment = null;
            var starterFragmentNeedsRemoval = (editor.querySelector('[ali-empty]') !== null)

            let insertNewFragment = (editor, otherEditor, position, newObj) => {
                let lastFragment = AlignerElement.asNode(AlignerElement.template({id: newObj.id, fragment:{text_fragment:newObj.text_fragment}, matrixRow:{row_id:newObj.row_id, type:newObj.type}}));
                editor.insertChildAtIndex(lastFragment, position);

                if (otherEditor.documentID) {
                    var dummy = AlignerElement.asNode(AlignerElement.template({matrixRow:{row_id:newObj.row_id, type:newObj.type}}));
                    otherEditor.insertChildAtIndex(dummy, position);
                }

                return lastFragment;
            }

            if (data.elements) {
                data.elements.forEach((elem) => {
                    var currentFragment = editor.querySelector(`[ali-rid="${elem.row_id}"]`);
                    if (currentFragment) currentFragment = editor.skipToLastUnconfirmed(currentFragment)
                    lastFragment = currentFragment ? currentFragment : lastFragment;

                    switch (elem.state) {
                        case "fixed":
                            if (starterFragmentNeedsRemoval) {
                                [].forEach.call((document.querySelectorAll("block-editor [ali-empty]")), el => el.remove());
                                starterFragmentNeedsRemoval = false;
                            }
                            var fixedElement = editor.querySelector(`[ali-rid="${elem.row_id}"]`)
                            if (fixedElement) {
                                fixedElement.setContent(elem.text_fragment.text_content, true);
                                fixedElement.setAttribute("ali-id", elem.id)
                                fixedElement.removeAttribute("ali-dummy")
                            } else {
                                let position = lastFragment ? (lastFragment.positionIndex() + 1) : 1;
                                lastFragment = insertNewFragment(editor,otherEditor, position, elem)
                            }

                            break;
                        case "updated":
                            if(elem.text_fragment && elem.text_fragment.text_content) editor.getFragmentByID(elem.id).setContent(elem.text_fragment.text_content, true);
                            if(elem.type) editor.getFragmentByID(elem.id).setElementType(elem.type, true);
                            //if(elem.url) this.editors.find(`[ali-rid="${elem.row_id}"]`).each((i, imgElem) => imgElem.setImageSource(elem.url))
                            //if(elem.width) this.editors.find(`[ali-rid="${elem.row_id}"]`).each((i, imgElem) => imgElem.setImageSize(elem.width))
                            break;
                        case "new":
                            if (starterFragmentNeedsRemoval) {
                                [].forEach.call((document.querySelectorAll("block-editor [ali-empty]")), el => el.remove());
                                starterFragmentNeedsRemoval = false;
                            }
                            let position = lastFragment ? (lastFragment.positionIndex() + 1) : 1;
                            lastFragment = insertNewFragment(editor, otherEditor, position, elem)
                            break;

                        // no default
                    }
                })
            }
        }

       // lastUpdatedUpdater.updateText(new Date());
    }

    constructLanguagePairFragments(matrix, masterElements, relatedElements) {
        let result = {
            master:[],
            related:[],
        };

        if (!Array.isArray(matrix)) return result;

        matrix.forEach(row => {
            let relatedFragment = null;
            let masterFragment = null;

            if (relatedElements && relatedElements.fragments) {
                let match =  Object.entries(relatedElements.fragments).find(([key, value]) => {
                    return value.row_id === row.row_id
                })

                if (match) relatedFragment = { id: match[0], fragment: match[1], matrixRow: row }
            }

            if (masterElements && masterElements.fragments) {
                let match =  Object.entries(masterElements.fragments).find(([key, value]) => {
                    return value.row_id === row.row_id
                })

                if (match) masterFragment = { id: match[0], fragment: match[1], matrixRow: row }
            }

            if (relatedFragment || masterFragment) {
                result.master.push(masterFragment ? masterFragment : { matrixRow: row } )
                result.related.push(relatedFragment ? relatedFragment : { matrixRow: row } )
            }
        });
    
        return result
    }

    _getOppositeEditor(editor){
       return this.masterEditor.isSameNode(editor) ? this.relatedEditor : this.masterEditor;
    }

    // Send pending changes
    _saveNowIfNeeded(editor) {
        if(editor.state === "waitingMerge") {
            clearTimeout(editor.mergeRequest)
            this._mergeRequest(this, editor)
        }
    }

    // Send pending changes with debounce
    _saveWithDebounce(editor) {
        this.callListeners("onStartSaving")
        editor.state = "waitingMerge"
        clearTimeout(editor.mergeRequest)
        editor.mergeRequest = setTimeout(() => {this._mergeRequest(editor)}, 1000)
    }

    // Send merge message
    _mergeRequest(editor){
        // IF document has not yet been correctly initialized we debounce it 
        if (!editor.documentID) {
            this._saveWithDebounce(editor)
            return  
        }

        var request = {}
        request.rows_deleted = editor.rows_deleted;
        request.deleted = editor.deleted;
        editor.deleted = [];        // Reset deleted array
        editor.rows_deleted = [];   // Reset deleted array
        editor.mergeRequest = null;
        request.elements = []
        request.title = editor.querySelector("ali-title content").textContent

        editor.state = "waitingMergeResponse"
        var mergeID = genUUID();

        // Todo delete / new (if any) / update separate list 
        var elems = editor.querySelectorAll(".aligner-fragment");
        [].forEach.call(elems, function(el) {
            var fragment = el.toObject(!el.hasAttribute("state"), el.getAttribute("state"))
            if (el.getAttribute("state") === "new") el.setAttribute("merge-id", mergeID)
            if (el.hasAttribute("state")) el.removeAttribute("state")
            request.elements.push(fragment)
        })

        this.ws.sendEventWithCB({
            requestID: mergeID, 
            targetDocID: editor.documentID, 
            event: "merge",
            data: request,
        }, mergeID).then(r => {
            [].forEach.call((editor.querySelectorAll(`[merge-id="${mergeID}"]`)), el => el.removeAttribute("merge-id"));
            if (!this.masterEditor.mergeRequest && !this.relatedEditor.mergeRequest) {
                this.callListeners("onFinishedSaving")
            }
        })
    }

    _setDocQueryParam(dl) {
        if (dl && 'URLSearchParams' in window) {
            var searchParams = new URLSearchParams(window.location.search)
            searchParams.set("dl",dl);
            var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
            window.history.replaceState(null, '', newRelativePathQuery);
        }
    }

    _startAutoAligner() {
        let editor1 = this.masterEditor;
        let self = this;

        // TODO Detect only when document is loaded
        // TODO limit that each realignment is done 2x
        if (this.masterEditor && this.relatedEditor) {
            this.resizeObserver = new ResizeObserver(() => {
                var elems = editor1.querySelectorAll("[ali-id]");
                [].forEach.call(elems, function(el) {
                    self.alignElems(el, el.getOppositeElement())
                })
            });
        
            // Observe one or multiple elements
            this.resizeObserver.observe(this.masterEditor, { box: 'border-box' });
            this.resizeObserver.observe(this.relatedEditor, { box: 'border-box' });
        }
    }

    alignElems(elem1, elem2) {
        if (!elem2) return;
        
        var height2 = elem1.querySelector("content").offsetHeight;
        var height = elem2.querySelector("content").offsetHeight;

        if (height < height2) {
            elem2.style.paddingBottom = (height2 - height) + "px";
            elem1.style.paddingBottom = "0px";
        } else if (height > height2) {
            elem1.style.paddingBottom = (height - height2) + "px";
            elem2.style.paddingBottom = "0px";
        } else {
            elem1.style.paddingBottom = "0px";
            elem2.style.paddingBottom = "0px";
        }
    }
}