import React, { useState }  from 'react'
import { Link } from "react-router-dom"
import './EditorNavbar.scss';
import logo from '../resources/small-logo.png'
import { extractInitials, timeDifference, toFormatedDateString } from '../logic/util'
import {OverlayTrigger, ProgressBar, Tooltip} from 'react-bootstrap'
import InviteUserModal from './modals/InviteUserModal';

class EditorNavbar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            updatedAt: "",
            saving: false,
            collectionID: "",
            translationCounter: null,
            users: [],
            documentLoaded: false,
            viewOnly: true,
            inviteModal: false,
        }

        this.nextTextUpdate = null;
        this.showInviteModal = this.showInviteModal.bind(this);
        this.closeInviteModal = this.closeInviteModal.bind(this);
    }

    componentDidMount() {
        this.props.eventManager.on("onDocumentConnected", ((collectionID, newDocumnt) => {
            this.setState(prevState => ({...prevState, documentLoaded : true, collectionID, viewOnly: !newDocumnt }));
        }))

        // TODO Find better event to link it to
        this.props.eventManager.on("onRelatedEditorLoaded", (relatedInfo, viewOnly) => {
            this.setState(prevState => ({...prevState, viewOnly}));
        })
        this.props.eventManager.on("onActiveUsersChange", ((users) => {
            this.setState(prevState => ({...prevState, users, documentLoaded : true }));
        }))

        this.props.eventManager.on("onTranslationCountChange", ((count, maxCount, role, isProPack )=> {
            isProPack = isProPack !== undefined ? isProPack : this.state.isProPack;
            this.setState(prevState => ({...prevState, translationCounter: count, translationCounterMax: maxCount, documentRole: role ? role : this.state.documentRole, isProPack}));
        }))
    }

    showInviteModal = () => this.setState(prevState => ({...prevState, inviteModal: true }))
    closeInviteModal = () => this.setState(prevState => ({...prevState, inviteModal: false }))

    uniq(users) {
        var seen = {};
        return users.filter(function(user) {
            return seen.hasOwnProperty(user.id) ? false : (seen[user.id] = true);
        });
    }
    
    render() {
        let users = this.uniq(this.state.users).map((el, i) => (
            <OverlayTrigger transition={false}   key={el.id} placement="bottom" overlay={<Tooltip>{el.name}</Tooltip>}>
                <div className="my-auto user-avatar mx-1">{extractInitials(el.name)}</div>
            </OverlayTrigger>
        ))
        
      return (
        <nav className="navbar navbar-expand-md editor-navbar  navbar-dark bg-white editor-navigation disable-select-box sticky-top">
            <div className={this.state.documentLoaded ? "d-flex w-100 nav-wrap justify-content-center" : "d-flex w-100 nav-wrap unstarted justify-content-between"}>
                <div className="left-side d-flex flex-even">
                    <OverlayTrigger transition={false} placement="bottom" delay={300}  overlay={<Tooltip>All my translations</Tooltip>}>
                        <Link to="../" className="home-link mr-3 d-flex">
                            <img className="small-logo" src={logo} alt="TranslateWise logo" />
                            <h1>Home</h1>   
                        </Link>
                    </OverlayTrigger>
                    <span className="updated-at text-gray my-auto mr-3">{this.state.updatedAt}</span>
                    <UpdatedAt eventManager={this.props.eventManager} />
                    <span className="editor-status text-gray my-auto"></span>
                </div>
                { this.state.documentLoaded && this.state.isProPack === false &&
                    <div className="free-tr text-center d-flex flex-row">
                        <div>
                            <FreeTrCounter translationCounter={this.state.translationCounter} translationCounterMax={this.state.translationCounterMax} />
                        </div>
                        {this.state.documentRole === "OWNER" && <Link to="../settings#subscription" className="free-tr-info my-auto"><i class="fas fa-gem"></i> Upgrade</Link>}
                    </div>
                }   
                 { this.state.documentLoaded && this.state.isProPack === true &&
                    <div className="pro-tr text-center d-flex flex-row">
                        <div className="my-auto"><i class="fas fa-gem"></i> Pro package</div>
                    </div>
                }   
               
                <div className="d-flex flex-even justify-content-end">
                    <div className="mr-5 d-flex">{ users }</div>
                    {this.state.documentLoaded && !this.state.viewOnly && (
                        <div>
                            <OverlayTrigger transition={false} placement="bottom" delay={300}  overlay={<Tooltip>Add new content. Previous translations will be saved.</Tooltip>}>
                            <a role="button" className="btn btn-primary" href="new">
                                <span>New document</span>
                            </a>
                            </OverlayTrigger>
                        </div>)}
                    {!this.state.viewOnly && 
                        <OverlayTrigger transition={false} placement="bottom" delay={300}  overlay={<Tooltip>Share this document</Tooltip>}>
                            <div id="invite-wrap"  className="btn btn-reverse mx-2 my-auto nav-action-icon" onClick={this.showInviteModal}>
                                <i className="fas fa-user-plus mx-1"></i><span>Invite</span>
                            </div>
                        </OverlayTrigger>
                    }
                </div>
            </div>
            <InviteUserModal show={this.state.inviteModal} onClose={this.closeInviteModal} collectionID={this.state.collectionID} match={this.props.match} />
        </nav>);
    }
}

export default EditorNavbar;

function FreeTrCounter(params) {
    let ratio = (params.translationCounter / params.translationCounterMax ) * 100;


    return (
        <OverlayTrigger transition={false} placement="bottom" delay={300}  overlay={<Tooltip>Used translation characters</Tooltip>}>
            <span className="free-tr-counter">
                <ProgressBar now={ratio} />
                {params.translationCounter} / {params.translationCounterMax}
            </span>
        </OverlayTrigger>
    )
}

class UpdatedAt extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            updatedAt: "",
            saving: false,
        }
    }
    
    componentDidMount() {
        this.props.eventManager.on("onStartSaving", (() => {
            this.setState({ updatedAt : "Saving...", saving: true });
            clearTimeout(this.nextTextUpdate)
        }));

        this.props.eventManager.on("onFinishedSaving", (() => {
            this.setState({ updatedAt : "Saved", saving: false });

            let timeNow = new Date()
            clearTimeout(this.nextTextUpdate)
            this.nextTextUpdate = setTimeout(() => {
                this.updateText(timeNow);
            }, 3000)
        }));
        
        this.props.eventManager.on("collaborationMerge", (() => {
            if (!this.state.saving) this.updateText(new Date());
        }));

        this.props.eventManager.on("onDocumentConnected", ((collectionID) => {
            this.setState({ documentLoaded : true, collectionID });
        }))

        // TODO Find better event to link it to
        this.props.eventManager.on("onRelatedEditorLoaded", (relatedInfo, viewOnly) => {
            this.setState({viewOnly})
        })
        this.props.eventManager.on("onActiveUsersChange", ((users) => {
            this.setState({ users, documentLoaded : true });
        }))
    }


    updateText(updatedTime) {
        var elapsed = new Date() - updatedTime;

        // last update was less then 5 minutes ago
        if (elapsed < 60 * 1000 * 5) {
            this.setState({ updatedAt : ("Last save was " +timeDifference(elapsed)) });

            clearTimeout(this.nextTextUpdate)
            this.nextTextUpdate = setTimeout(() => {
                this.updateText(updatedTime)
            }, 1500)
        } else {
            this.setState({ updatedAt : ("Last save was " +toFormatedDateString(updatedTime))});
        }
    }

    render() {
        return (<span className="updated-at text-gray my-auto mr-3">{this.state.updatedAt}</span>)
    }
}