import React from 'react'
import DefaultNavbar from '../components/DefaultNavbar'
import Sidebar from '../components/Sidebar'
import DocumentCard from '../components/DocumentCard'
import { documentAPI } from '../logic/api'
import { Link } from "react-router-dom"
import { addErrorToast } from '../components/ToastManager'
import { catchHandler } from '../logic/util'
import './ListView.scss'
import SubscriptionModal from '../components/modals/SubscriptionModal'
import CancelSubscriptionModal from '../components/modals/CancelSubscriptionModal'

const PAGE_DOC_COUNT = 60;

class ListView extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            workspaceRole : "",
            hasMoreDocuments: true,
            loadInProgress: true,
            documents: [],
            workspaces: [],
            currentWorkspace: {},
        }

        this.removeDocumentCard = this.removeDocumentCard.bind(this);
        this.onScroll = this.onScroll.bind(this);
    }

    onScroll(ev) {
        if (this.state.hasMoreDocuments && !this.state.loadInProgress) {
            if((ev.target.scrollTop + 600) >= (ev.target.scrollHeight - ev.target.offsetHeight)) {
                this.setState({ loadInProgress: true });
                documentAPI.documents(this.props.match.params.ws, {resultsCount: PAGE_DOC_COUNT, start: this.state.documents.length}).then(documents => {
                    let hasMoreDocuments = (PAGE_DOC_COUNT === documents.length);
                    this.setState({ documents: [...this.state.documents, ...documents ], loadInProgress: false, hasMoreDocuments }) 
                }).catch(e => {
                    addErrorToast("Could not fetch more documents")
                    this.setState({ loadInProgress: false, hasMoreDocuments:false }) 
                })
            }
        }
    }

    _getDocs(wsID) {
        this.setState({ loadInProgress: true, hasMoreDocuments: true });

        Promise.all([
            documentAPI.workspaces(),
            documentAPI.documents(wsID, {resultsCount: PAGE_DOC_COUNT, start: 0})
        ].map(p => p.catch(catchHandler))).then(([workspaces, documents]) => {
            if (workspaces.error) workspaces = [];
            if (documents.error) documents = [];

            let currentWorkspaceAr = workspaces.filter(ws=> ws.workspace_id === parseInt(this.props.match.params.ws));
            let currentWorkspace = currentWorkspaceAr.length === 1 ?  currentWorkspaceAr[0].workspace : {};
            let workspaceRole =  currentWorkspaceAr.length >= 1 ? currentWorkspaceAr[0].ws_role : "";
            let hasMoreDocuments = (PAGE_DOC_COUNT === documents.length);
            let loadInProgress = false;
            this.setState({ workspaces, currentWorkspace, workspaceRole,  documents, loadInProgress, hasMoreDocuments });
        }).catch(e => {
            addErrorToast("Unexpected error")
        });
    }

    componentWillReceiveProps(newProps) {
       this._getDocs(newProps.match.params.ws)
    }

    componentDidMount() {
       this._getDocs(this.props.match.params.ws)
    }

    removeDocumentCard(documentID) {
        let newDocs = this.state.documents.filter(d=> d.id !== documentID);
        this.setState({documents: newDocs});   
    }

    render() {
        let documents = this.state.documents.map((el, i) => (
            <DocumentCard key={el.id} document={el} workspaceRole={this.state.workspaceRole} removeDocumentCard={this.removeDocumentCard} />
        ))

        let showNewDocCard = (documents.length !== 0 || !this.state.loadInProgress)

        return (
            <div className="list-view">
                <DefaultNavbar currentWorkspace={this.state.currentWorkspace} />
                <div className="list-view-content">
                    <Sidebar match={ this.props.match } workspaces={this.state.workspaces} />
                    <div style={{ width:"100%", padding:"24px 124px", overflow:"scroll", height:"calc(100vh - 65px)"}} onScroll={this.onScroll}>
                        <div className="grid-container">
                            { ( showNewDocCard && ["OWNER", "EDIT"].includes(this.state.workspaceRole)) && 
                                <Link to="documents/new" className="document-card new-document d-flex">
                                    <div className="m-auto d-flex"><div>New</div><div className="plus">+</div></div>
                                </Link>}
                            { ( showNewDocCard && !["OWNER", "EDIT"].includes(this.state.workspaceRole)) && 
                                <div className="document-card new-document d-flex not-allowed">
                                    <div className="m-auto d-flex">
                                        <div>New</div><div className="plus">+</div>
                                    </div>
                                    <div className="not-allowed-details">You have view-only rights in this workspace</div>
                                </div> }
                            { documents }
                         </div>
                    </div>
                </div>
            </div>);
    }
}

export default ListView;

function getLoadingCards() {
    let loaders = [];

    for (let index = 0; index < 30; index++) {
        loaders.push(<LoadingCard />);
    }

    return loaders;
}

function LoadingCard() {
    return (
        <div className="loading-card document-card">
            <div className="line-1 load-content"></div>
            <div className="line-2 load-content"></div>
            <div className="line-3 load-content"></div>
        </div>
    )
}