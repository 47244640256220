import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import { documentAPI } from '../../logic/api'
import { extractInitials } from '../../logic/util'
import { withRouter } from 'react-router-dom';
import { addInfoToast, addErrorToast } from '../ToastManager'
import { isEmail } from './../../logic/util'
import './InviteUserModal.scss'
import './Modal.scss'

class InviteUserModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRole: "EDIT",
            docUsers: [],
            email: "",
        }

        this.shareDocument = this.shareDocument.bind(this)
        this.handleEmailChange = this.handleEmailChange.bind(this)
    }

    componentDidMount() {
        if (this.props.collectionID) {
            documentAPI.getDocumentUsers(this.props.match.params.ws, this.props.collectionID).then(results => {
                this.setState({ docUsers: results.users })
            })
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.collectionID) {
            documentAPI.getDocumentUsers(newProps.match.params.ws, newProps.collectionID).then(results => {
                this.setState({ docUsers: results.users })
            })
        }
    }


    handleEmailChange(event) {
        this.setState({ email: event.target.value });
    }

    changeSelectedToView = () => this.setState({ selectedRole: "VIEW" })
    changeSelectedToEdit = () => this.setState({ selectedRole: "EDIT" })

    shareDocument() {
        let email = this.state.email;
        let role = this.state.selectedRole;

        if (email === "") {
            addErrorToast("Email is required")
            return
        }

        if (!isEmail(email)) {
            addErrorToast("A valid email is required")
            return
        }

        documentAPI.addDocumentUser(this.props.match.params.ws, this.props.collectionID, { email, role }).then(r => {
            documentAPI.getDocumentUsers(this.props.match.params.ws, this.props.collectionID).then(results => {
                addInfoToast("User invited")
                this.setState({ docUsers: results.users, email: "" })
            })
        }).catch(() => {
            addErrorToast("Something went wrong")
        })
    }

    render() {
        let docUsers = this.state.docUsers.map((el, i) => (
            <UserRow key={el.user_id} wsUser={el} docID={this.props.collectionID} />
        ))

        return (
            <Modal className="tw-modal invite-user-modal" size="lg" centered show={this.props.show} onHide={this.props.onClose} onShow={() => this.setState({ email: "" })} animation={false}>
                <h5 className="modal-title my-2">Share document</h5>
                <p className="footnote">Invite member to view or edit document</p>
                <div className="input-container d-flex">
                    <input id="share-email" type="email" placeholder="work@email.com" value={this.state.email} onChange={this.handleEmailChange} />
                    <DropdownButton id="share-role-selector" title={this.state.selectedRole === "VIEW" ? "Can view" : "Can edit"} menuAlign="right">
                        <Dropdown.Item onClick={this.changeSelectedToView}>Can view</Dropdown.Item>
                        <Dropdown.Item onClick={this.changeSelectedToEdit}>Can edit</Dropdown.Item>
                    </DropdownButton>
                    <Button className="ml-4 w-25" variant="primary" onClick={this.shareDocument}>
                        Send invite
                        </Button>
                    <small className="hint">
                        Email
                        </small>
                </div>


                <div className="mt-4 mb-2 mx-3 d-flex">
                    <div className="row user">
                        {docUsers}
                    </div>
                </div>
            </Modal>
        );
    }
}

export default withRouter(InviteUserModal);

function UserRow(props) {
    return (<>
        <div className="col-8 username mb-3">
            <div className="avatar d-flex align-items-center justify-content-center">
                {extractInitials(props.wsUser.user.name)}
            </div>
            <span>
                <p className={props.wsUser.user.name ? "full-name" : "full-name pending"}>{props.wsUser.user.name ? props.wsUser.user.name : "Invite pending"}<span className="member">{props.wsUser.ws_role ? "(Member)" : ""}</span></p>
                <span className="user-email">{props.wsUser.user.email}</span>
            </span>
        </div>
        <div className="col-4 role mb-3 text-dark-gray">
            {props.wsUser.ws_role ? roleDisplayName(props.wsUser.ws_role) : roleDisplayName(props.wsUser.document_role[props.docID])}
        </div>
    </>
    )
}

function roleDisplayName(role) {
    switch (role) {
        case "EDIT":
            return "Editor";
        case "VIEW":
            return "Viewer";
        case "OWNER":
            return "Owner";
    }
}