import AlignerElement from './core-element'
import { genUUID } from '../../util'

class AlignerText extends AlignerElement {
	constructor() {
		super();
		this.content = this.querySelector("content")
		this.addEventListener('focusin', this._focusHandler.bind(this));
		this.addEventListener('focusout', this._focusOutHandler.bind(this))
		this.addEventListener('click', this._clickHandler.bind(this))
		this.addEventListener('mousedown', this._mousedownHandler.bind(this))
	}

	connectedCallback() {
		// Special case for new document first input field
		if (!this.content)
			this.content = this.querySelector("content")
	}

	setContent(text, omitEvent) {
		this.querySelector("content").innerHTML = (text);
		if (!omitEvent) this.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }));
	}

	appendText(text) {
		this.querySelector("content").append(text)
	}

	changeListLevel(increase) {
		let newStyle = "p"
		let oldType = this.getElementType();
		let isOrdered = this.getElementType().endsWith("ol");

		let match = oldType.match(/(\d*)-/);
		let newIndex = increase ? (parseInt(match[1]) + 1) : (parseInt(match[1]) - 1);

		if (newIndex > 3) newIndex = 3
		if (newIndex >= 1) newStyle = isOrdered ? newIndex + "-ol" : newIndex + "-ul";

		if (newStyle !== oldType) this.setElementType(newStyle)
	}

	addCursor(toBeginning) {
		let tmpRange = document.createRange();
		tmpRange.selectNodeContents(this.querySelector("content"))
		tmpRange.collapse(toBeginning);
		window.getSelection().removeAllRanges()
		window.getSelection().addRange(tmpRange)
	}

	_mousedownHandler(e) {
		if (e.target.closest(".update-translation")) e.preventDefault();
	}

	translateFromOpposite() {
		this.querySelector(".update-translation").classList.add("loading")
		this.parentElement.triggerHandler("onTranslateContent", { element: this, text: this.getOppositeElement().querySelector("content").innerHTML })
	}

	_clickHandler(e) {
		if (e.target.closest(".update-translation")) {
			this.translateFromOpposite();
			return
		}

		if (!e.target.closest("content")) {
			this.addCursor(false);
		}
	}
	_focusHandler() {
		var opposite = this.getOppositeElement();
		if (opposite) opposite.classList.add('active');
		this.classList.add('active')

		let info = this.elementViewportInfo()

		if (!info.isInView) {
			this.scrollIntoView(info.rect.top < 0);
			// TODO extra elem that has offset from bottm
		}
	}

	_focusOutHandler(e) {
		var opposite = this.getOppositeElement();
		if (opposite) opposite.classList.remove('active');
		this.classList.remove('active')
	}
}

AlignerText.newNode = (type, textFragment) => {
	let node = AlignerElement.asNode(AlignerText.template(genUUID(), null, { type }))
	node.querySelector("content").append(textFragment ? textFragment : "")
	return node
}

AlignerText.emptyElement = (emptyRid) => {
	return AlignerText.template(null, null, { row_id: emptyRid }, false, true)
}

//TOD CLean unknown characters
AlignerText.template = (id, fragment, matrixRow, readOnly, empty) => `
	<ali-txt class="aligner-fragment" 
			ali-type="${matrixRow.type ? matrixRow.type : "p"}" 
			ali-id="${id ? id : genUUID()}" 
			ali-rid="${(matrixRow && matrixRow.row_id) ? matrixRow.row_id : genUUID()}"
			${!id ? 'ali-dummy="true"' : ""}
			${empty ? 'ali-empty="true"' : ""}>
		<content contenteditable="${readOnly ? "false" : "true"}" spellcheck="false" placeholder="Click on translate button or write translation here">${(fragment && fragment.text_fragment && fragment.text_fragment.text_content) ? fragment.text_fragment.text_content : ""}</content>
		<div class="extra">
			<div class="update-translation" title="Update translation">
				<div class="inner-circle">
					<i class="fas fa-language"></i><div class="loader">Loading...</div>
				</div>
			</div>
		</div>
		<div class="active-users"></div>
	</ali-txt>`


if (!window.customElements.get("ali-txt"))
	customElements.define('ali-txt', AlignerText);

export default AlignerText;