import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { executePromiseMinTime } from '../../logic/util'
import './OverFreeLimitModal.scss'
import './Modal.scss'
import { withRouter } from "react-router";

class OverFreeLimitModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: false }
        this.confirmClick = this.confirmClick.bind(this)
    }

    confirmClick() {
        this.props.onClose()
        this.props.history.push(this.props.data.subscriptionLink);
    }

	render() {
		return (
            <Modal className="tw-modal over-limit-modal" centered show={this.props.show} onHide={this.props.onClose} animation={false}>
                <div className="modal-icon">
                    <i class="fas fa-infinity"></i>
                </div>
                {this.props.data.role === "OWNER" && 
                    <>
                    <div className="title">Free trial is over</div>
                    <div className="title-details">Get unlimited access to the best translation technology<br></br><br></br>With just €4,99/month you can use all the features.</div>
                    <div className="mx-auto my-3 d-flex">
                        <button onClick={this.confirmClick} className="subsribe-btn"><span>Upgrade now</span><span className="off-50">Get 50% off</span></button>
                        <button className="cancel-btn" onClick={this.props.onClose}>Ignore offer</button>
                    </div>
                    </>}
                {this.props.data.role !== "OWNER" && 
                    <>
                    <div className="title">Free trial is over</div>
                    <div className="title-details">This workspace needs to be upgraded by the owner<br></br><br></br>With just €4,99/month you can use all the features.</div>
                    <div className="mx-auto my-3 d-flex">
                        <button className="cancel-btn" onClick={this.props.onClose}>Close</button>
                    </div>
                    </>}
            </Modal>
		);
	}
}

export default withRouter(OverFreeLimitModal);