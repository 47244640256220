import { addLockedErrorPopup } from '../../components/PopupManager';
import {genUUID} from '../util'

export default class EditorWS {
  constructor(eventManager) {
    this.eventManager = eventManager;
    this.promiseCbs = {};
    this.unPushedActions = [];

      if (!navigator.onLine) { 
          this.eventManager.callListeners("onOffline")
      } // TODO

      window.addEventListener("offline", (() => {
        this.eventManager.callListeners("onOffline")
      }));
  }

  connect(documentID) {
    this.socket = new WebSocket(this._buildURL(documentID));
    this.pageLeaveHandler();

    this.socket.onclose = (function (e) { 

      switch (e.code) {
        case 4002: // Document deleted
          addLockedErrorPopup({
            title: "Document has been deleted",
            body: "This document is no longer accessible",
            confirmText: "Back",
            hideClose: true,
        }, ()=> {  window.location.href = "../"; });
        break;

        case 4004: // Access denied
          addLockedErrorPopup({
            title: "Access denied",
            body: "This document is not accessible",
            confirmText: "Back",
            hideClose: true,
        }, ()=> {  window.location.href = "../"; });
        break;

        case 4001: // Server restart
        case 4003: // Document timed out
        default:
          this.eventManager.callListeners("onConnetionLost")
          break;
      }
    }).bind(this); 

    this.socket.onopen = (() => {
      this.unPushedActions.forEach((data) => {
        this.sendEvent(data);
      });
    })

    this.socket.onmessage = ((event) => {
      try {
        var response = JSON.parse(event.data);
        if (response.requestID && this.promiseCbs[response.requestID]) {
          if (response.status === "ERROR") {
            this.promiseCbs[response.requestID].reject(response)
          } else {
            this.promiseCbs[response.requestID].resolve(response)
          }

          delete this.promiseCbs[response.requestID]
        }

        this.eventManager.incomingMessage(response);
      } catch (e) {
        console.log("ERROR", e)
      }
    })
  }

  _buildURL(documentID) {
    var protocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
    var uri = protocol + window.location.host;
    return uri += '/api/session/' + documentID;
  }

  close() {
    if(this.socket) {
      this.socket.onclose = function () {}; // Remove onclose
      this.socket.close()
    }
  }

  sendEventWithCB(data, requestID) {
    requestID = requestID ? requestID : genUUID();
    data.requestID = requestID

    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      this.unPushedActions.push(data)
    } else {
      this.socket.send(JSON.stringify(data));
    }

    return new Promise((function(resolve, reject) {
      this.promiseCbs[requestID] = {resolve, reject}
    }).bind(this));
  }

  sendEvent(data) {
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      this.unPushedActions.push(data)
      return
    }

    this.socket.send(JSON.stringify(data));
  }

  pageLeaveHandler() {
    var self = this;
    window.onbeforeunload = function () {
      self.socket.onclose = function () { };
      self.socket.close();
    };
  }
}
