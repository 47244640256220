import AlignerTitle from './elements/title-element'
import AlignerText from './elements/text-element'
import AlignerElement from './elements/core-element'
import PasteParser from './paste'
import { selection } from '../selection'
import './toolBar'

export default class BlockEditor extends HTMLElement {
    constructor() {
        super();

        // TODO Move all variables to single object
        this.editorInfo = {
            deleted: [],
            rows_deleted: [],
            lastDragstart: null,
            multiselectActive: false,
        };

        this.addEventListener('mouseup', ((e) => { this.editorInfo.lastDragstart = null }))
        this.addEventListener('mousedown', ((e) => { this.editorInfo.lastDragstart = e }))
        this.addEventListener('mouseout', this.mouseLeaveHandler.bind(this));
        this.addEventListener('keydown', this.keydownHandler.bind(this));
        this.addEventListener('input', this.inputHandler.bind(this));
        this.addEventListener('copy', this.copyHandler.bind(this));
        this.addEventListener('paste', this.pasteHandler.bind(this));
        this.addEventListener('drop', e => e.preventDefault());
        this.addEventListener('dragstart', e => e.preventDefault());
        this.addEventListener('dragenter', e => e.preventDefault());
        this.handler = null;

        this.deleted = []; // Elements deleted since last merge
        this.rows_deleted = [] // Rows deleted since last merge
    }

    setDocumentInfo (meta, role, viewOnly) {
        this.editorInfo.meta = meta;
        this.editorInfo.role = role;
        this.editorInfo.viewOnly = viewOnly;
        if (viewOnly) {
            this.classList.add("view-only");
        } else {
            this.classList.remove("view-only");
        }
    }

    addEventHandler(handler) {
        this.handler = handler;
    }

    triggerHandler(eventType, eventData) {
        if (this.handler && this.handler.editorEventHandler)
            return this.handler.editorEventHandler(eventType, this, eventData)
    }

    skipToLastUnconfirmed(target) {
        while(target && target.nextElementSibling && target.nextElementSibling.hasAttribute("merge-id"))
            target = target.nextElementSibling;
        return target;
    }

    getFragmentByID(id) {
        return this.querySelector(`[ali-id="${id}"]`)
    }

    getFragmentByRID(rid) {
        return this.querySelector(`[ali-rid="${rid}"]`)
    }

    insertAfter(newElement, previouselement) {
        if (previouselement.nextElementSibling) {
            newElement = this.insertBefore(newElement, previouselement.nextElementSibling)
        } else {
            this.append(newElement)
        }
    }

    insertChildAtIndex(child, index) {
        if (!index) index = 0
        if (index >= this.children.length) {
            this.appendChild(child)
        } else {
            this.insertBefore(child, this.children[index])
        }
    }

    handleContentChange(target) {
        let targetFragment = target.closest("ali-txt, ali-title")
        if (targetFragment.getAttribute("ali-empty")) {
            this.triggerHandler("onAddFragment", { element: targetFragment, position: 0 });
        } else if (targetFragment.getAttribute("ali-dummy")) {
            this.triggerHandler("onFixFragment", { element: targetFragment })
        } else {
            this.triggerHandler("onChangeContentFragment", { element: targetFragment })
        }
    }

    inputHandler(e) {
        this.handleContentChange(e.target)
    }

    keydownHandler(e) {
        e = e || window.event;
        if (this.editorInfo.viewOnly) return;
        let currentElement = getCurrentFragment();

        switch (e.keyCode) {
            // Arrow down (40) Arrow up (38)
            case 40:
            case 38:
                e.preventDefault();
                var isMoveUp = (e.keyCode === 38);

                // If user tries to switch fragments
                if (isMoveUp ? isCursorAtStartOfFragment(true) : isCursorAtEndOfFragment(true)) {
                    if (isMoveUp && currentElement && currentElement.previousElementSibling)
                        currentElement.previousElementSibling.addCursor(false)

                    if (!isMoveUp && currentElement && currentElement.nextElementSibling)
                        currentElement.nextElementSibling.addCursor(true)

                    break;
                }

                moveLine(isMoveUp, e.shiftKey)
                break

            // Arrow left (37), Arrow right (39)
            case 37:
            case 39:
                var isMoveRight = (e.keyCode === 39);

                // If user tries to switch fragments
                if (isMoveRight && isCursorAtEndOfFragment() && currentElement.nextElementSibling) {
                    e.preventDefault();
                    currentElement.nextElementSibling.addCursor(true)
                } else if (!isMoveRight && isCursorAtStartOfFragment() && currentElement.previousElementSibling) {
                    e.preventDefault();
                    currentElement.previousElementSibling.addCursor(false)
                }

                break

            // Enter
            case 13:
                e.preventDefault();

                // If enter in title we just add move to next element 
                // TODO validate next element is text element ?
                if (currentElement.nodeName.toUpperCase() === "ALI-TITLE") {
                    currentElement.nextElementSibling.addCursor(false)
                    break;
                }


                if (isCursorAtStartOfFragment() && !isCursorAtEndOfFragment()) {
                    let newElement = AlignerText.newNode(getCurrentFragment().getNewElementType())
                    this.triggerHandler("onAddFragment", { element: newElement, position: getCurrentFragment().positionIndex() });
                    this.insertBefore(newElement, getCurrentFragment())
                    newElement.addCursor(true);
                    break;
                }

                // Todo if first the we ad to pos 0 else we add index + 1
                var extractedContent = extractContentRightFromCursor(true);
                if (extractedContent.textContent)
                    this.triggerHandler("onChangeContentFragment", { element: getCurrentFragment(), position: getCurrentFragment().positionIndex() });

                let newElement = AlignerText.newNode(getCurrentFragment().getNewElementType(), extractedContent)

                if (getCurrentFragment().getAttribute("ali-empty")) {
                    this.triggerHandler("onAddFragment", { element: getCurrentFragment(), position: 0 });
                }

                this.triggerHandler("onAddFragment", {
                    element: newElement,
                    position: (getCurrentFragment().positionIndex() + 1),
                })

                this.insertAfter(newElement, getCurrentFragment())
                newElement.addCursor(true);
                break
            // Backspace
            case 8:
                // No special actions for title
                if (currentElement.nodeName.toUpperCase() === "ALI-TITLE") break;

                // TODO if cursor in element and delete 2x into empty elem -> funn conent elem will be selected

                // Starts with cursor
                if (isCursorAtStartOfFragment()) {
                    e.preventDefault();
                    let previousFragment = currentElement.previousElementSibling;
                    if (previousFragment.nodeName === "ALI-TITLE") return
                    
                    // If current element is list item just decrease its level
                    if (currentElement.isListFragment() && (currentElement.content || currentElement.getAttribute("ali-dummy"))) {
                        currentElement.changeListLevel(false)
                        break
                    }

                    if (!previousFragment && currentElement.content.textContent) return; // Avoid deleting all of first row

                    let extractedContent = extractContentRightFromCursor(false);

                    if (previousFragment) previousFragment.addCursor(false); // TEMP

                    if (currentElement.getAttribute("ali-dummy")) break

                    if (extractedContent.textContent) {
                        this.triggerHandler("onChangeContentFragment", { element: currentElement });
                        if (previousFragment) {
                            previousFragment.content.append(extractedContent)
                            this.handleContentChange(previousFragment)
                        }
                    }

                    // We ask eventmanager if we should delete fragment completely or make it dummy
                    if (this.triggerHandler("onDeleteFragment", { element: currentElement })) {
                        currentElement.remove();
                    } else {
                        currentElement.setAttribute("ali-dummy", true);

                        // Special case where total length of editor might not change and elements heights would be off
                        this.handler.alignElems(currentElement, currentElement.getOppositeElement())
                        if (previousFragment) this.handler.alignElems(previousFragment, previousFragment.getOppositeElement())
                    }
                }

                break

            // Tab
            case 9:
                e.preventDefault()

                // If current element is list item just increase/decrease its level
                if (currentElement.isListFragment())
                    currentElement.changeListLevel(!e.shiftKey)
                
                break

            // no default
        }
    }

    addMultipleFragments(fragments, index) {
        let self = this;
        let currentFragment = getCurrentFragment();
        if (!currentFragment) return;

        let currentIndex = currentFragment.positionIndex();

        fragments.forEach((elem, i) => {
            // If current element is empty we fill it with fragment content
            let currentChild = self.children[currentIndex]
            if (currentChild && currentChild.content.textContent.length === 0) {
                currentChild.setContent(elem.content.innerHTML)
                if (i+1 === fragments.length) currentChild.addCursor(false)
            } else {
                if (i === 0) currentIndex++;
                self.triggerHandler("onAddFragment", { element: elem, position: (currentIndex)});
                self.insertChildAtIndex(elem, (currentIndex))
                if (i+1 === fragments.length) elem.addCursor(false)
            }

            currentIndex++;
        })
    }

    copyHandler(e) {
        if (this.editorInfo.multiselectActive) {
            e.preventDefault()
            this.copyMultiSelectToClipboard(e)
        }
    }

    pasteHandler(e) {
        e.preventDefault();
        if (this.editorInfo.viewOnly) return;

        var pasteParser = new PasteParser(e);

        if (pasteParser.isMultiElementEvent()) {
            this.addMultipleFragments(pasteParser.foundFragments)
        } else {
            pasteParser.insertFoundTextToCursor();
            this.handleContentChange(e.target)
        }

        this.normalize(); // TODO ? Maybe not ?
    }

    copyMultiSelectToClipboard(e) {
        e.preventDefault();

        // Each selected fragment will be added to clipboard with content only (removing all helpers)
        let htmlCopy = document.createElement("div");
        let lastListElements = {
            ol:[],
            ul:[],
        };

        var elems = this.querySelectorAll('*[ali-selected="true"]');
        var fragmentTexts = [].map.call(elems, (elem) => {
            var tmpObj = elem.cloneNode(true);
            if (!tmpObj.getAttribute("ali-dummy")){
                let type = tmpObj.getElementType()
                if (!type) return {plain:""} // TODO TITLE

                let listHandler = (listType) => {
                    let lvl = parseInt(type.substring( 0, type.indexOf("-")));
                    let li =  document.createElement("li");
                    li.innerHTML = tmpObj.content.innerHTML;

                    if (lastListElements[listType].length < lvl) {
                        for (let index = lastListElements[listType].length; index < lvl; index++) {
                            let listElem = document.createElement(listType);
                            if (index === 0) {
                                htmlCopy.append(listElem)
                            } else {
                                lastListElements[listType][lastListElements[listType].length - 1].append(listElem)
                            }

                            lastListElements[listType].push(listElem)
                        }
                    } else if (lastListElements[listType].length !== lvl) {
                        lastListElements[listType] = lastListElements[listType].slice(0, lvl)
                    }

                    lastListElements[listType][lastListElements[listType].length - 1].append(li)
                }

                if (type.endsWith("-ol")) {
                    listHandler("ol")
                } else if(type.endsWith("-ul")) {
                    listHandler("ul")
                } else if (type === "h1") {
                    let htmlElem = document.createElement("h1")
                    htmlElem.innerHTML = tmpObj.content.innerHTML;
                    htmlCopy.append(htmlElem)
                } else {
                    let htmlElem = document.createElement("p")
                    htmlElem.innerHTML = tmpObj.content.innerHTML;
                    htmlCopy.append(htmlElem)
                }

                if (!type.endsWith("-ul"))
                    lastListElements.ul = [];

                if (!type.endsWith("-ol"))
                    lastListElements.ol = [];
                
                return {plain: tmpObj.content.textContent, amld: tmpObj.toObject()}
            }

            return {plain:""};
        })

        var plainText = fragmentTexts.map(f => f.plain).join("\n");
        var amldArr = fragmentTexts.map(f => f.amld)

        var clipboardData = (e.originalEvent || e).clipboardData;
        clipboardData.setData("text/html", htmlCopy.innerHTML);
        clipboardData.setData("text/plain", plainText);
        clipboardData.setData("text/amld", JSON.stringify(amldArr));

        e.stopPropagation();
    }

    mouseLeaveHandler(e) {
        // If user drags from fragment to outside we start selection box from the initial drag start area
        if (e.target.tagName === "CONTENT" && this.editorInfo.lastDragstart && e.toElement && !(e.target.compareDocumentPosition(e.toElement) & Node.DOCUMENT_POSITION_CONTAINED_BY)) {
            
            var ev = new MouseEvent('mousedown', {
                'view': window,
                'bubbles': true,
                'cancelable': true,
                'clientX': this.editorInfo.lastDragstart.clientX,
                'clientY': this.editorInfo.lastDragstart.clientY,
            });
        
            Object.defineProperty(ev, 'target', {value: this});
            selection.trigger(ev, true)

            //selection.trigger(this.editorInfo.lastDragstart, true)
            this.editorInfo.lastDragstart = null;
        }
    }

    initialLanguageDetector() {
        var self = this;

        let initDetection = (e) => {
            var text = "";
            var elems = self.querySelectorAll("content");
            [].forEach.call(elems, (elem) => { text += elem.textContent })
            if (text.length > 25) {
                self.removeEventListener("paste", initDetection)
                self.removeEventListener("input", initDetection)
                self.triggerHandler("onDetectLanguage", { text })
            }
        }

        this.addEventListener("paste", initDetection)
        this.addEventListener("input", initDetection)
    }

    loadContent(fragments, title, emptyRid, readonly, isRTL) {
        if (isRTL) {
            this.classList.add("rtl")
        } else {
            this.classList.remove("rtl")
        }

        var source = AlignerTitle.template(title, readonly);
        if (Array.isArray(fragments) && fragments.length > 0) {
            fragments.forEach((el) => source += AlignerElement.template(el, readonly))
        } else {
            source += AlignerText.emptyElement(emptyRid)
        }

        this.innerHTML = source;
    }
}

if (!window.customElements.get("block-editor")) window.customElements.define('block-editor', BlockEditor);

let isCursorAtStartOfFragment = (allowSellection) => {
    if (window.getSelection().rangeCount > 0) {
        var testRange = window.getSelection().getRangeAt(0).cloneRange();
        if (allowSellection) testRange.collapse(true)
        testRange.setStartBefore(getCurrentContentElem());
        return (testRange.toString().length === 0);
    }
}

let isCursorAtEndOfFragment = (allowSellection) => {
    if (window.getSelection().rangeCount > 0) {
        var testRange = window.getSelection().getRangeAt(0).cloneRange();
        if (!allowSellection) testRange.collapse(true)
        testRange.setStartBefore(getCurrentContentElem());
        return (testRange.toString().length === getCurrentContentElem().innerText.length);
    }
}

let moveLine = (isMoveUp, extend) => {
    window.getSelection().modify(extend ? "extend" : "move", isMoveUp ? "left" : "right", "line");
}

let getCurrentContentElem = () => {
    if (document.activeElement && document.activeElement.nodeName.toUpperCase() === "CONTENT")
        return document.activeElement;
}

let getCurrentFragment = () => {
    return getCurrentContentElem() ? getCurrentContentElem().closest("ali-txt, ali-title") : null;
}

let extractContentRightFromCursor = (deleteSelection) => {
    var contentRange = window.getSelection().getRangeAt(0).cloneRange() // Make clone so not to actively change selection
    if (deleteSelection) contentRange.deleteContents()

    var lastNode = getCurrentContentElem().lastChild

    // If content is not empty
    if (lastNode) contentRange.setEndAfter(lastNode)

    return contentRange.extractContents()
}