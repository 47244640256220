import React, { useState } from 'react'
import { Link } from "react-router-dom"
import DefaultNavbar from '../components/DefaultNavbar'
import Sidebar from '../components/Sidebar'
import Button from 'react-bootstrap/Button'
import { documentAPI } from '../logic/api'
import './Settings.scss'
import { addInfoToast, addErrorToast } from '../components/ToastManager'
import { addSubscriptionPopup, addConfirmPopup, addCancelSubscriptionPopup, addAnyModal } from '../components/PopupManager'
import { extractInitials, toLowerAndCapitalize, toFormatedDateString } from '../logic/util'
import InviteTeamUserModal from '../components/modals/InviteTeamUserModal'
import ConfirmModal from '../components/modals/ConfirmModal'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Modal from 'react-bootstrap/Modal'
import { checkPassword } from '../logic/util'


class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            workspaces: [],
            firstLoadTab: "",
            workspaceUsers: [],
            currentWorkspace: {},
            workspaceRole: "",
        }

        this.showInviteModal = this.showInviteModal.bind(this);
        this.closeInviteModal = this.closeInviteModal.bind(this);
        this.showConfirmModal = this.showConfirmModal.bind(this);
        this.closeConfirmModal = this.closeConfirmModal.bind(this);
        this.deleteTeam = this.deleteTeam.bind(this);
        this.leaveTeam = this.leaveTeam.bind(this);
        this.loadAll = this.loadAll.bind(this);
    }

    loadAll(firstLoadTab) {
        Promise.all([
            documentAPI.workspaces(),
            documentAPI.getWorkspaceUsers(this.props.match.params.ws),
            documentAPI.me(),
        ]).then(([workspaces, workspaceUsers, user]) => {
            let currentWorkspaceAr = workspaces.filter(ws => ws.workspace_id === parseInt(this.props.match.params.ws));
            let currentWorkspace = currentWorkspaceAr.length === 1 ? currentWorkspaceAr[0].workspace : {};
            let workspaceRole = currentWorkspaceAr[0].ws_role;
            this.setState({ workspaces, currentWorkspace, workspaceRole, workspaceUsers, user, inviteModal: false, confirmModal: false, firstLoadTab });
        }).catch(e => {
            console.log(e)
            addErrorToast("Unable to fetch data. Unexpected error")
        });

    }

    componentDidMount() {
        // TODO change hash on tab change
        if (window.location.hash) {
            this.loadAll(window.location.hash.substring(1));
        } else {
            this.loadAll("workspace");
        }
    }

    showInviteModal = () => this.setState({ inviteModal: true })
    closeInviteModal = () => this.setState({ inviteModal: false })
    showConfirmModal = () => this.setState({ confirmModal: true })
    closeConfirmModal = () => this.setState({ confirmModal: false })

    deleteTeam() {
        documentAPI.deleteWorkspace(this.props.match.params.ws).then((result) => {
            let nextWS = this.state.workspaces.find(ws => ws.workspace_id !== parseInt(this.props.match.params.ws));
            if (nextWS) {
                addInfoToast("Team deleted")
                this.props.history.push("/workspace/" + nextWS.workspace.id + "/");
            } else {
                this.props.history.push("/workspace/new");
            }
        }).catch(e => {
            addErrorToast("Unable to delete team")
        });
    }

    leaveTeam() {
        documentAPI.leaveWorkspace(this.props.match.params.ws).then((result) => {
            let nextWS = this.state.workspaces.find(ws => ws.workspace_id !== parseInt(this.props.match.params.ws));
            if (nextWS) {
                addInfoToast("Left team")
                this.props.history.push("/workspace/" + nextWS.workspace.id + "/");
            } else {
                this.props.history.push("/workspace/new");
            }
        }).catch(e => {
            addErrorToast("Unable to leave team")
        });
    }

    render() {
        return (
            <div className="settings-view">
                <DefaultNavbar currentWorkspace={this.state.currentWorkspace} />
                <div className="settings-view-content w-100">
                    <Sidebar match={this.props.match} workspaces={this.state.workspaces} />
                    <div className="container mt-5">
                        <div className="row mb-4 team-settings">
                            <Link className="back-link d-flex align-items-center text-gray mb-4" to="./"><i className="far fa-long-arrow-alt-left mr-2"></i> Back</Link>
                            <div className="col-12 mb-5 actionsBar row d-flex header">
                                <div className="col d-flex align-items-center">
                                    <div className="d-flex mx-auto">
                                        <div className="settings nav nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                            <a className={this.state.firstLoadTab === "workspace" ? "nav-link active" : "nav-link"} id="pills-workspace-tab" data-toggle="pill" href="#pills-workspace" role="tab" aria-controls="pills-workspace" >Workspace</a>
                                            {this.state.workspaceRole === "OWNER" && <a className={this.state.firstLoadTab === "subscription" ? "nav-link active" : "nav-link"} id="pills-subscription-tab" data-toggle="pill" href="#pills-subscription" role="tab" aria-controls="pills-subscription" >Subscription</a>}
                                            <a className={this.state.firstLoadTab === "personal" ? "nav-link active" : "nav-link"} id="pills-personal-tab" data-toggle="pill" href="#pills-personal" role="tab" aria-controls="pills-personal">Personal</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-content w-100" id="pills-tabContent">
                                <div className={this.state.firstLoadTab === "personal" ? "tab-pane fade show active" : "tab-pane fade"} className="tab-pane fade " id="pills-personal" role="tabpanel" aria-labelledby="pills-personal-tab">
                                    <PersonalSettings user={this.state.user} />
                                </div>
                                <div className={this.state.firstLoadTab === "workspace" ? "tab-pane fade show active" : "tab-pane fade"} id="pills-workspace" role="tabpanel" aria-labelledby="pills-workspace-tab">
                                    <WorkspaceSettings
                                        user={this.state.user}
                                        workspaceRole={this.state.workspaceRole}
                                        currentWorkspace={this.state.currentWorkspace}
                                        teamUsers={this.state.workspaceUsers}
                                        showInviteModal={this.showInviteModal}
                                        showConfirmDeleteModal={this.showConfirmModal}
                                        leaveTeam={this.leaveTeam}
                                        reloadAll={this.loadAll} />
                                </div>
                                {this.state.workspaceRole === "OWNER" &&
                                    <div className={this.state.firstLoadTab === "subscription" ? "tab-pane fade show active" : "tab-pane fade"} id="pills-subscription" role="tabpanel" aria-labelledby="pills-subscription-tab">
                                        <SubscriptionSettings user={this.state.user} currentWorkspace={this.state.currentWorkspace} reloadAll={this.loadAll} />
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <InviteTeamUserModal show={this.state.inviteModal} onClose={this.closeInviteModal} onInvited={this.loadAll} match={this.props.match} />
                <ConfirmModal show={this.state.confirmModal} onClose={this.closeConfirmModal} onConfirm={this.deleteTeam} data={{
                    title: "Delete team",
                    body: "Are you sure you want to delete the team ?",
                }} />
            </div>);
    }
}

export default Settings;



function SubscriptionSettings(props) {
    var cancelSubPopup = () => {
        addCancelSubscriptionPopup({
            endDate: toFormatedDateString(props.currentWorkspace.subscription_period_end),
            currentWorkspaceID: props.currentWorkspace.id,
        }, () => {
            props.reloadAll("subscription");
        });
    }

    var reactivateSubPopup = () => {
        addConfirmPopup({
            includeLoading: true,
            title: "Reactivate subscription",
            body: "Need to continue using unlimited translation?",
            confirmText: "Activate Subscription",
        }, () => {
            return documentAPI.reActivateSubscription(props.currentWorkspace.id).then(() => {
                addInfoToast("Subscription activated")
                props.reloadAll("subscription");
            }).catch(() => {
                props.reloadAll("subscription");
                addErrorToast("Something went wrong")
            });
        })
    }

    var activePlan = (ws) => {
        if (ws.current_package !== "" && (ws.subscription_status === "active" || ws.subscription_status === "trialing"))
            return ws.current_package

        return ""
    }

    var workspaceSubscriptionStatus = (subscriptionPackage, ws) => {
        if (ws.current_package === subscriptionPackage && ws.is_canceled === true)
            return "cancelled"

        if (ws.current_package === subscriptionPackage && (ws.subscription_status === "active" || ws.subscription_status === "trialing"))
            return "active"

        return ""
    }

    var anyWorkspaceSubscriptionStatus = (ws) => {
        if (ws.current_package && ws.is_canceled === true)
            return "cancelled"

        if (ws.current_package && (ws.subscription_status === "active" || ws.subscription_status === "trialing"))
            return "active"

        return ""
    }

    const currentPackage = activePlan(props.currentWorkspace)

    return (
        <div className="subsciption-tab">
            <h3 className="main-text-title">Free for personal use</h3>
            <div className="main-text d-flex">
                <span className="mx-auto w-50">
                    In all plans, you can translate into 140 languages, edit, share, and store your content in TranslateWise web app
                </span>
            </div>
            <div className="row subscriptions mb-5 mt-2">
                <div className={currentPackage === "" ? "col active" : "col"}>
                    <h3>Personal</h3>
                    <div>Free</div>
                    <hr></hr>
                    <ul>
                        <li>Instant translation <b>18000 characters (3600 words)</b></li>
                        <li>Parallel editor</li>
                        <li>Content storing</li>
                        <li>Collaboration</li>
                    </ul>
                    {anyWorkspaceSubscriptionStatus(props.currentWorkspace) === "" && <Button disabled={true} className="mt-3">Active</Button>}
                    {anyWorkspaceSubscriptionStatus(props.currentWorkspace) === "active" && <Button onClick={cancelSubPopup} className="mt-3">Downgrade</Button>}
                    {anyWorkspaceSubscriptionStatus(props.currentWorkspace) === "cancelled" && <Button disabled={true} className="mt-3">Active from {toFormatedDateString(props.currentWorkspace.subscription_period_end, true)}</Button>}
                </div>
                <div className={currentPackage === "PRO_PACK" ? "col mx-5 active" : "col mx-5"}>
                    <LimitedOffer />
                    <h3>Personal Pro</h3>
                    <div><span className="price">€4,99</span> /Month</div>
                    <hr></hr>
                    <ul>
                        <li>Instant translation <b>500000 characters (100000 words) monthly</b></li>
                        <li>Parallel editor</li>
                        <li>Content storing</li>
                        <li>Collaboration</li>
                    </ul>

                    {workspaceSubscriptionStatus("PRO_PACK", props.currentWorkspace) === "" && <Button className="mt-5" onClick={() => { addSubscriptionPopup("PRO_PACK", () => { props.reloadAll("subscription") }) }}>Subscribe</Button>}
                    {workspaceSubscriptionStatus("PRO_PACK", props.currentWorkspace) === "active" && <>
                        <p className="mt-5 mb-1 text-secondary">Next billing {toFormatedDateString(props.currentWorkspace.subscription_period_end, true)}</p>
                        <Button className="" onClick={cancelSubPopup}>Cancel</Button>
                    </>}
                    {workspaceSubscriptionStatus("PRO_PACK", props.currentWorkspace) === "cancelled" && <>
                        <p className="mt-5 mb-1 text-secondary">Active until {toFormatedDateString(props.currentWorkspace.subscription_period_end, true)}</p>
                        <Button onClick={reactivateSubPopup}>Resubscribe</Button>
                    </>}
                </div>
                <div className="col">
                    <h3>Team</h3>
                    <div>Starting <span className="price">€29.99</span> /Month</div>
                    <hr></hr>
                    <ul>
                        <li>Instant translation Unlimited</li>
                        <li>All as in Personal Pro</li>
                        <li>Versioning history</li>
                        <li>Workspaces and features for teams</li>
                    </ul>
                    <Button disabled={true} className="mt-3">Coming soon</Button>
                </div>
            </div>
        </div>
    )
}

function PersonalSettings(props) {
    const [initalSet, setInitialSet] = useState(false);
    const [showSetPasswordModal, setSetPasswordModal] = useState(false);
    const [showChangePasswordModal, setChangePasswordModal] = useState(false);

    let pwdSetSuccess = (type) => {
        if (type === "initial") {
            setInitialSet(true)
            setSetPasswordModal(false)
        } else {
            setChangePasswordModal(false)
        }
    }

    return (
        <div className="col-12 team-form">
            <div className="header d-flex align-items-center mb-4">
                <p className="mb-0">General</p>
            </div>
            <div className="name-row d-flex align-items-center mb-4 row">
                <div className="col-6">
                    <input type="text" placeholder="Name" name="user-name" disabled value={props.user.name} />
                </div>
                <div className="col-6">
                    <input type="text" name="team-url" placeholder="Email" disabled value={props.user.email} />
                </div>
            </div>
            <div>
                <div className="d-flex align-items-center mb-4 row">
                    <div className="col-12 d-flex">
                        <p className="my-auto">Password</p>
                        {(!initalSet && !props.user.password_hash) && <Button className="ml-auto" variant="outline-primary" onClick={() => setSetPasswordModal(true)}>Add</Button>}
                        {(initalSet || props.user.password_hash) && <Button className="ml-auto" variant="outline-primary" onClick={() => setChangePasswordModal(true)}>Change</Button>}

                    </div>
                </div>
            </div>
            <PasswordModal show={showSetPasswordModal} onClose={() => setSetPasswordModal(false)} pwdSetSuccess={pwdSetSuccess} />
            <ChangePasswordModal show={showChangePasswordModal} onClose={() => setChangePasswordModal(false)} pwdSetSuccess={pwdSetSuccess} />
        </div>)
}


function WorkspaceSettings(props) {
    let teamUsers = props.teamUsers.map((el, index) => <TeamMemberRow isMe={props.user.id === el.user.id} leaveTeam={props.leaveTeam} reloadAll={props.reloadAll} workspaceRole={props.workspaceRole} key={el.user.id} wsUser={el} />);

    return (<>
        <div className="col-12 team-form">
            <div className="header d-flex align-items-center mb-4">
                <p className="mb-0">General</p>
                {(props.workspaceRole === "OWNER") &&
                    <button id="delete-account" className="remove-team ml-auto d-flex align-items-center" onClick={props.showConfirmDeleteModal}>
                        <i className="far fa-trash-alt mr-2"></i>
                Delete the team
            </button>}
            </div>
            <div className="name-row d-flex align-items-center mb-4 row">
                <div className="col-12">
                    <input type="text" placeholder="Team name" name="team-name" disabled value={props.currentWorkspace.name} />
                </div>
            </div>
        </div>

        <div className="invite-team-members">
            <div className="actionsBar row d-flex header">
                <div className="col d-flex align-items-center">
                    <div className="d-flex flex-column mr-auto">
                        <h5 className="semi">Members</h5>
                    </div>
                    {(props.workspaceRole === "OWNER") && <Button onClick={props.showInviteModal}> Invite more members<i className="fas fa-user-plus ml-2 fa-sm" aria-hidden="true"></i></Button>}
                </div>
            </div>
            <div className="members-list row">
                <div className="col d-flex align-items-center flex-column">
                    {teamUsers}
                </div>
            </div>
        </div>
    </>)
}

function TeamMemberRow(props) {
    const [showRemoveUserModal, setRemoveUserModal] = useState(false);
    const [showLeaveTeamModal, setLeaveTeamModal] = useState(false);


    let currentRole = props.wsUser.ws_role ? toLowerAndCapitalize(props.wsUser.ws_role) : "Document collaborator"
    let showDropdown = props.workspaceRole === "OWNER" && props.wsUser.ws_role !== "OWNER"

    let removeUser = () => {
        documentAPI.deleteWorkspaceUser(props.wsUser.workspace_id, props.wsUser.user.id).then(() => {
            addInfoToast("User removed")
            props.reloadAll();
        }).catch(e => {
            addErrorToast("Could not remove user")
        });
    }

    let changeRoleTo = (role) => {
        let email = props.wsUser.user.email;
        documentAPI.changeWorkspaceUser(props.wsUser.workspace_id, { email, role }).then(r => {
            addInfoToast("Role changed")
            props.reloadAll()
        }).catch(() => {
            addErrorToast("Something went wrong. Could not change role")
        })
    }

    return (
        <div className="member d-flex align-items-center mb-3">
            <div className="author d-flex align-items-center">
                <div className="avatar d-flex align-items-center justify-content-center">
                    {extractInitials(props.wsUser.user.name)}
                </div>
                <div>
                    <p className={props.wsUser.user.name ? "author-name" : "author-name pending"}>{props.wsUser.user.name ? props.wsUser.user.name : "Invite pending"}</p>
                    <p className="email">{props.wsUser.user.email}</p>
                </div>
                <div className="actions ml-auto"></div>
            </div>
            <div className="dropdown add-new-team-member ml-auto">
                {showDropdown &&
                    <DropdownButton id="share-role-selector" title={currentRole} menuAlign="right">
                        <Dropdown.Item onClick={() => { changeRoleTo("VIEW") }}>Can view</Dropdown.Item>
                        <Dropdown.Item onClick={() => { changeRoleTo("EDIT") }}>Can edit</Dropdown.Item>
                        <Dropdown.Item onClick={() => setRemoveUserModal(true)}>Remove</Dropdown.Item>
                    </DropdownButton>}
                {(props.isMe && props.workspaceRole !== "OWNER") && (<Button className="mr-4" variant="outline-primary" onClick={() => setLeaveTeamModal(true)}>Leave</Button>)}
                {!showDropdown && <span>{currentRole}</span>}

            </div>
            <ConfirmModal show={showRemoveUserModal} onClose={() => setRemoveUserModal(false)} onConfirm={removeUser} data={{
                title: "Remove user ?",
                body: "Are you sure you want to remove " + props.wsUser.user.email + " from team ?",
            }} />
            <ConfirmModal show={showLeaveTeamModal} onClose={() => setLeaveTeamModal(false)} onConfirm={props.leaveTeam} data={{
                title: "Leave team ?",
                body: "Are you sure you want to leave team ?",
            }} />
        </div>
    )
}

function PasswordModal(props) {
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [errors, setErrors] = useState({});

    let initPassword = (event) => {
        event.preventDefault();

        if (validateForm()) {
            documentAPI.setInitPassword({ password, passwordConfirm }).then(() => {
                addInfoToast("Password set")
                props.pwdSetSuccess("initial")
            }).catch(err => {
                setErrors({ password: "Something went wrong" })
            })
        }
    }

    function validateForm() {
        var validated = true;
        let tmpErrors = {}

        if (password.length < 1) {
            tmpErrors = Object.assign(tmpErrors, { password: "Password is required" })
            validated = false;
        } else if (!checkPassword(password)) {
            tmpErrors = Object.assign(tmpErrors, { password: "Password must match requirements" })
            validated = false;
        }

        if (passwordConfirm.length < 1) {
            tmpErrors = Object.assign(tmpErrors, { passwordConfirm: "Confirm password is required" })
            validated = false;
        } else if (password !== passwordConfirm) {
            tmpErrors = Object.assign(tmpErrors, { passwordConfirm: "Passwords must match" })
            validated = false;
        }

        if (!validated) setErrors(tmpErrors)
        return validated
    }

    return (
        <Modal className="tw-modal set-password-modal" centered show={props.show} onHide={props.onClose} animation={false}>
            <h5 className="modal-title my-2">Set password</h5>
            <p className="footnote">Set password to allow regular sign in with email and password</p>
            <form onSubmit={initPassword}>
                <div>
                    <div className="input-container d-flex">
                        <input id="share-email" type="password" placeholder="New password" value={password} onChange={e => setPassword(e.target.value)} />
                        <small className="hint">New password</small>
                        <small className="hint-error">{errors.password}</small>
                    </div>
                    <div className="input-container d-flex mt-3">
                        <input id="share-email" type="password" placeholder="Confirm password" value={passwordConfirm} onChange={e => setPasswordConfirm(e.target.value)} />
                        <small className="hint">Confirm password</small>
                        <small className="hint-error">{errors.passwordConfirm}</small>
                    </div>
                    <div className="row password-requirements px-4 mt-3 mb-4">
                        <div className="col-6">One lowercase character</div>
                        <div className="col-6">One uppercase character</div>
                        <div className="col-6">One number</div>
                        <div className="col-6">6 characters minimum</div>
                    </div>
                </div>
                <div className="mt-4 mb-2 mx-3 d-flex">
                    <div className="ml-auto"></div>
                    <Button className="mr-3" variant="outline-primary" onClick={props.onClose}>
                        Cancel
                </Button>
                    <Button variant="primary" type="submit">
                        Set password
                </Button>
                </div>
            </form>
        </Modal>
    )
}


function ChangePasswordModal(props) {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
    const [errors, setErrors] = useState({});

    let changePassword = (event) => {
        event.preventDefault();

        if (validateForm()) {
            documentAPI.changePassword({ currentPassword, newPassword, newPasswordConfirm }).then(() => {
                addInfoToast("Password changed")
                props.pwdSetSuccess()
            }).catch(err => {
                if (err.status_code && err.status_code === "WRONG_PASSWORD") {
                    setErrors({ currentPassword: "Wrong password" })
                } else {
                    setErrors({ currentPassword: "Something went wrong" })
                }
            })
        }
    }

    function validateForm() {
        var validated = true;
        let tmpErrors = {}

        if (currentPassword.length < 1) {
            tmpErrors = Object.assign(tmpErrors, { currentPassword: "Password is required" })
            validated = false;
        }

        if (newPassword.length < 1) {
            tmpErrors = Object.assign(tmpErrors, { newPassword: "New password is required" })
            validated = false;
        } else if (!checkPassword(newPassword)) {
            tmpErrors = Object.assign(tmpErrors, { newPassword: "Password must match requirements" })
            validated = false;
        }

        if (newPasswordConfirm.length < 1) {
            tmpErrors = Object.assign(tmpErrors, { newPasswordConfirm: "Confirm password is required" })
            validated = false;
        } else if (newPassword !== newPasswordConfirm) {
            tmpErrors = Object.assign(tmpErrors, { newPasswordConfirm: "Passwords must match" })
            validated = false;
        }

        if (!validated) setErrors(tmpErrors)
        return validated
    }

    let reset = () => {
        setCurrentPassword("");
        setNewPassword("");
        setNewPasswordConfirm("");
        setErrors({});
    }

    return (
        <Modal className="tw-modal set-password-modal" centered onShow={reset} show={props.show} onHide={props.onClose} animation={false}>
            <h5 className="modal-title my-2">Change password</h5>
            <p className="footnote">Change current password</p>
            <form onSubmit={changePassword}>
                <div>
                    <div className="input-container d-flex">
                        <input id="share-email" type="password" placeholder="Current password" value={currentPassword} onChange={e => setCurrentPassword(e.target.value)} />
                        <small className="hint">Current password</small>
                        <small className="hint-error">{errors.currentPassword}</small>
                    </div>
                    <div className="input-container d-flex mt-3">
                        <input id="share-email" type="password" placeholder="New password" value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                        <small className="hint">New password</small>
                        <small className="hint-error">{errors.newPassword}</small>
                    </div>
                    <div className="input-container d-flex mt-3">
                        <input id="share-email" type="password" placeholder="Confirm password" value={newPasswordConfirm} onChange={e => setNewPasswordConfirm(e.target.value)} />
                        <small className="hint">Confirm password</small>
                        <small className="hint-error">{errors.newPasswordConfirm}</small>
                    </div>
                    <div className="row password-requirements px-4 mt-3 mb-4">
                        <div className="col-6">One lowercase character</div>
                        <div className="col-6">One uppercase character</div>
                        <div className="col-6">One number</div>
                        <div className="col-6">6 characters minimum</div>
                    </div>
                </div>
                <div className="mt-4 mb-2 mx-3 d-flex">
                    <div className="ml-auto"></div>
                    <Button className="mr-3" variant="outline-primary" onClick={props.onClose}>
                        Cancel
                </Button>
                    <Button variant="primary" type="submit">
                        Change password
                </Button>
                </div>
            </form>
        </Modal>
    )
}

function LimitedOffer(params) {
    return (
        <div className="limited-offer">
            <div className="offer-percentage">
                50% Off
            </div>
            <span>Limited offer</span>
        </div>
    )

}